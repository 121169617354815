import React, { useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material';
import { Accordion, AccordionSummary } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FAQText } from '../../common/Header';
import FormattedContent from '../../common/FormattedContent';

const CustomAccordion = ({ apiFunction }) => {
  const [content, setContent] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const response = await apiFunction();
      setContent(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [apiFunction]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {content.map((item, index) => (
        <CustomizedAccordion
          key={index}
          // square='false'
        >
          <AccordionSummary
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{
              borderRadius: '3.75rem',
              boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
              backgroundColor: '#F4F8FA',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: '#F7F7F7',
                position: 'absolute',
                top: '-1px',
                left: 0,
              }}
            ></div>
            <AccordionContainer>
              <FAQText style={{ fontWeight: 'bolder' }}>
                {item.attributes.question}
              </FAQText>
              <FontAwesomeIcon icon={faAngleDown} className='search-icon' />
            </AccordionContainer>
          </AccordionSummary>
          <FAQText
            style={{
              marginTop: '1rem',
              marginLeft: '1rem',
              fontWeight: 'bolder',
            }}
          >
            <FormattedContent content={item.attributes.answer} />
          </FAQText>
        </CustomizedAccordion>
      ))}
    </>
  );
};
export default CustomAccordion;

const AccordionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.blueberry.main,
}));

const CustomizedAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&::before': {
      backgroundColor: 'transparent',
    },
  },
  paddingBottom: '.5rem',
  minWidth: '100%',
  boxShadow: 'none',
  border: 'none',
}));
