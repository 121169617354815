import { createTheme } from "@mui/material";
const theme = createTheme({
  components: {
    MuiAccordion: {
      root: {
        "&::before": {
          background: 'red', // Disable the background
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#434343",
    },
    white: { main: "#FFFFFF" },
    pencilgray: { main: "#5B5B5B" },
    gray2: { main: "#9e9a99" },
    black: { main: "#000000" },
    darkBlue: { main: "#56595C" },
    lightBlue: { main: "#2E5A6D" },
    green: { main: "#909661" },
    darkbeige: { main: "#A17F6E" },
    beige: { main: "#B7946F" },
    lightbeige: { main: "#F6C695" },
    blueberry: {main: "#395874"},
    seablue: {main:"#5B8EBB"},
    stormyblue: {main:"#94A8B2"},
    blossom: {main:"#9F5A5D"},
    rose: {main:"#FFE3E4"},
    light: {main: "#F4F8FA"},
    pencil: {main: "#5B5B5B"},
    cinammon: {main: "#A17F6E"},
    toast: {main: "#B7946F"},
    peach: {main: "#F6C695"},
    lime: {main: "#909661"},
    placeholder: {main: "#979B9E"},
    tag: {main: "#CDDAE1"},
  },
  typography: {
    heading1: {
      fontFamily: "Avenir",
      fontSize: 80,
      fontWeight: 700,
    },
    heading2: {
      fontFamily: "Avenir",
      fontSize: 60,
      fontWeight: 700,
    },
    heading3: {
      fontFamily: "Avenir",
      fontSize: 30,
      fontWeight: 700,
    },
    heading4: {
      fontFamily: "Avenir",
      fontSize: 24,
      fontWeight: 700,
    },
    largeText: {
      fontFamily: "Avenir",
      fontSize: 24,
      fontWeight: 400,
    },
    normalText: {
      fontFamily: "Avenir",
      fontSize: 16,
      fontWeight: 400,
    },
    boldText: {
      fontFamily: "Avenir",
      fontSize: 16,
      fontWeight: 700,
    },
    title: {
      fontFamily: "Avenir",
      fontSize: 48,
      fontWeight: 600,
      textTransform: "none",
      color: "#FFF",
      padding: 0,
      margin: 0,
    },
    counter: {
      fontFamily: "Avenir",
      fontSize: 60,
      color: "white",
    },
    counterLabel: {
      fontFamily: "Avenir",
      fontSize: 20,
      fontWeight: 500,
      color: "white",
    },
    menuItems: {
      fontFamily: "Avenir",
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
      textDecoration: "none",
      color: "#FFF",
    },
    button: {
      fontFamily: "Avenir",
      fontWeight: 500,
      fontSize: 16,
      textTransform: "none",
      textDecoration: "none",
      color: "#FFF",
    },
    input: {
      fontFamily: "Avenir",
      fontSize: 16,
      fontWeight: 500,
      textTransform: "none",
      textDecoration: "none",
      color: "#FFF",
    },
  },
  globals: {
    container: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor: "#000000",
      padding: "20px 90px",
    },
    screenContainer: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor: "#000000",
      padding: "20px 90px",
    },
    containerColumnCenterCenterFullscreen: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "20px 90px",
    },
    containerYCenterFullscreen: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "20px 90px",
    },
    containerYFlexstartFullscreen: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      boxSizing: "border-box",
      padding: "20px 90px",
    },
    containerYFlexstartLeftFullscreen: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      boxSizing: "border-box",
      padding: "20px 90px",
    },
    containerYSpacebetweenFullscreen: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "20px 90px",
    },
    containerXSpacebetweenFullscreen: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "20px 90px",
    },
    containerColumnTopLeft: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      boxSizing: "border-box",
    },
    containerColumnCenterLeft: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      boxSizing: "border-box",
    },
    containerRowCenterLeft: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      alignItems: "center",
      boxSizing: "border-box",
    },
    containerRowCenter: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    containerColumnTopCenter: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      boxSizing: "border-box",
    },
    containerYCenter: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
    },
    containerYCenterRight: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      boxSizing: "border-box",
    },
    containerYFlexstart: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      boxSizing: "border-box",
    },
    containerYSpacebetween: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
    },
    containerYFlexstartLeft: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      boxSizing: "border-box",
    },
    containerYFlexendRight: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "end",
      boxSizing: "border-box",
    },
    containerXCenter: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
    },
    containerXFlexend: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      boxSizing: "border-box",
    },
    containerXSpacebetween: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
    },
    containerXFlexstart: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      boxSizing: "border-box",
    },
    navbarSectionContainer: {
      position: 'fixed',
      zIndex: '999',
      width: '100%',
      display: 'flex',
      padding: '2rem',
      transition: 'top 0.5s',
    },
    iconContainer: {
      width: "100%",
      height: "100%",
    },
    inputContainer: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor: "#000000",
      padding: "20px 50px",
    },
    leftAlign: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "100px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
