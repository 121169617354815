import { styled } from '@mui/material';


const TableDefault = styled('table')(({ theme }) => ({
    width: '100%',
    borderRadius: '2rem',
    borderCollapse: 'collapse',
    '& td': {
        border: 'none',
    },
}));

const HeaderTable = styled('thead')(({ theme }) => ({
    backgroundColor: theme.palette.blueberry.main,
    fontSize: '1.25rem',
    fontFamily: 'Avenir',
    color: theme.palette.white.main,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]:{
        fontSize: '1rem',
    } 
}));

const GrayRow = styled('tr')(({ theme }) => ({
    backgroundColor: '#E0E0E0',
    fontSize: '1rem',
    fontFamily: 'Avenir',
    color: theme.palette.pencilgray.main,
    textAlign: 'center',
    border: 'none',
    padding: '1rem',
    [theme.breakpoints.down('lg')]:{
        fontSize: '.8rem',
    } 
}))

const WhiteRow = styled('tr')(({ theme }) => ({
    backgroundColor: '#F2F2F2',
    fontSize: '1rem',
    fontFamily: 'Avenir',
    color: theme.palette.pencilgray.main,
    textAlign: 'center',
    border: 'none',
    padding: '1rem',
    [theme.breakpoints.down('lg')]:{
        fontSize: '.8rem',
    } 
}))
const Cell = styled('td')(({ theme }) => ({
    padding: '.25rem',
    [theme.breakpoints.down('lg')]:{
        padding: '.1rem',
    } 
}))


export {
    Cell,
    GrayRow,
    HeaderTable,
    TableDefault,
    WhiteRow

};