import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { Content, ContainerDefault } from '../../common/Containers';
import { Description, HeaderFlexStart, Title } from '../../common/Header';
import { styled } from '@mui/material';

import news1 from '../../images/news1.png';

function ArticuloNoticia() {
    // const { title } = useParams();
    useEffect(() => {
        document.title = "Noticias - Hospital San Diego de Alcalá";
    }, []);


    const ImageSection = styled('img')(({ theme }) => ({
        width: '37.5rem',
        height: '22.04544rem',
        [theme.breakpoints.down('sm')]: {
            width: '19rem',
            height: '11rem',
        },
    }));

    return (
        <ContainerDefault>
            <Content>
                <Body>
                    <HeaderFlexStart>
                        <Title>Certificación 2023 Distintivo H</Title>
                        <Description>
                        🥇 Hoy celebramos el segundo año consecutivo que recibimos el Distintivo H por parte de la Secretaría de Turismo de Sonora, otorgado por la calidad, servicio e higiene de los alimentos que se preparan tanto en la cafetería, como en la cocina hospitalaria.🏥 Hoy reafirmamos nuestro compromiso de mantener los mayores estándares de calidad en todos los alimentos y bebidas que se sirven en #TuHospital.🙌🏻 Seguimos trabajando para brindarle lo mejor a ti y tu familia. #SanDiegoDeAlcala #Salud #Hospital #Sonora #food #cafetería #comida
                        </Description>
                    </HeaderFlexStart>
                    <ImageSection
                        alt=''
                        src={news1}
                    />
                </Body>
            </Content>
        </ContainerDefault>
    );
}

export default ArticuloNoticia;

const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '2rem',
    padding: '2rem 1rem',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
    },
}));