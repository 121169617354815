import React, { useEffect, useState } from 'react';
import SingleText from '../../components/SingleText';
import { getPrivacyPolicy } from '../../api/getPrivacyPolicy';

import {ContainerDefault} from '../../common/Containers';

const AvisoPrivacidad = () => {
    const [content, setContent] = useState([]);

    useEffect(() => {
        document.title = "Aviso de Privacidad - Hospital San Diego de Alcalá";
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await getPrivacyPolicy();
            setContent(response.data.attributes);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ContainerDefault>
            <SingleText name="Aviso Privacidad" attributes={content} />
        </ContainerDefault>
    );
};

export default AvisoPrivacidad;