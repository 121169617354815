import React, {useEffect, useState} from 'react';
import SingleText from '../../components/SingleText';
import { getTermandCondition } from '../../api/getTermsandConditions';

import {ContainerDefault} from '../../common/Containers';

const TerminosyCondiciones = () => {
    const [content, setContent] = useState([]);

    useEffect(() => {
        document.title = "Términos y Condiciones - Hospital San Diego de Alcalá";
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await getTermandCondition();
            setContent(response.data.attributes);
        } catch(error){
            console.error(error);
        }
    };


    return (
        <ContainerDefault>
            <SingleText name="TerminosyCondiciones" attributes={content}/>
        </ContainerDefault>
    );
};

export default TerminosyCondiciones;