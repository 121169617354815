import { createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAuth } from '../api/auth';
import Swal from 'sweetalert2';

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [session, setSession] = useState(() => {
    const storedSession = localStorage.getItem('session');
    return storedSession !== 'undefined' ? JSON.parse(storedSession) : null;
  });

  const handleLogin = async (identifier, password) => {
    let userData = JSON.stringify({
      identifier: identifier,
      password: password,
    });

    try {
      const response = await userAuth(userData);
      if (response.jwt) {
        const session = response;
        setSession(session);
        localStorage.setItem('session', JSON.stringify(session));
        navigate('/pacientes');
        const origin = location.state?.from?.pathname || '/pacientes';
        navigate(origin);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Usuario o contraseña incorrectos',
          confirmButtonColor: '#DD6B55',
        });
      }
    } catch (err) {
      console.error('Login failed:', err);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    setSession(null);
  };

  const value = {
    session,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
