import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { getHeroSection } from '../../api/getHeroSection';

//Carousel
import './styles.css';
import { Carousel } from 'react-responsive-carousel';
import { ContainerYCenterFlexstart } from '../../common/Containers';
import PrimaryButton from '../../common/PrimaryButton';
import theme from '../../styles';

const HeroSection = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const response = await getHeroSection();
      setContent(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ height: '100vh', width: '100vw' }}></div>
      ) : (
        <Carousel
          autoPlay
          showThumbs={false}
          stopOnHover
          useKeyboardArrows
          infiniteLoop
          showStatus={false}
        >
          {content.map((item) => {
            return (
              <div key={item.id}>
                <Background
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_CMS_URL +
                      item.attributes.imageDesktop.data.attributes.url})`,
                  }}
                >
                  <ContainerYCenterFlexstart
                    style={{
                      display:
                        item.attributes.button === null ? 'none' : 'flex',
                    }}
                  >
                    <PrimaryButton
                      text={item.attributes.button}
                      url={item.attributes.buttonurl}
                      backgroundColor={theme.palette.blueberry.main}
                    />
                  </ContainerYCenterFlexstart>
                </Background>
                <BackgroundMobile
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_CMS_URL +
                      item.attributes.imageMobile.data.attributes.url})`,
                  }}
                >
                  {' '}
                  <ContainerYCenterFlexstart
                    style={{
                      display:
                        item.attributes.button === null ? 'none' : 'flex',
                    }}
                  >
                    <PrimaryButton
                      text={item.attributes.button}
                      url={item.attributes.buttonUrl}
                      backgroundColor={theme.palette.blueberry.main}
                    />
                  </ContainerYCenterFlexstart>
                </BackgroundMobile>
              </div>
            );
          })}
        </Carousel>
      )}
    </>
  );
};

export default HeroSection;

const Background = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'contain',
  position: 'relative',
  height: '600px',
  minWidth: '1512px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));
const BackgroundMobile = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'contain',
  position: 'relative',
  height: '400px',
  minWidth: '1008px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));
