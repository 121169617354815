import React, {useEffect} from 'react';
import FilterMedicos from '../../components/FilterMedicos';
import {ContainerDefault} from '../../common/Containers';

const Medicos = () => {

    useEffect(() => {
        document.title = "Médicos - Hospital San Diego de Alcalá";
    }, []);
    return (
        <ContainerDefault>
            <FilterMedicos name="Especialidades"/>
        </ContainerDefault>
    );
}

export default Medicos;