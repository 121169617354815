import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { getMenu } from '../../api/getMenu';

//Components
import { ContainerDefault } from '../../common/Containers';
import { HeaderFlexStart, Title } from '../../common/Header';
import { Content } from '../../common/Containers';

const MenuCafeteria = () => {
    const [image, setImage] = useState('');

    const fetchData = async () => {
        try {
            const response = await getMenu();
            setImage(response.data.attributes.image.data.attributes);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        document.title = "Menú - Hospital San Diego de Alcalá";
    }, []);

    const ImageSection = styled('img')(({ theme }) => ({
        width: '70rem',
        [theme.breakpoints.down('sm')]: {
            width: ' 20rem',
        },
    }));
return (
    <ContainerDefault>
        <Content>
            <HeaderFlexStart
                style={{ padding: '0 1rem' }}>
                <Title>Menú de Cafetería</Title>
            </HeaderFlexStart>
            <ImageSection
                        alt=''
                        src={process.env.REACT_APP_CMS_URL + image.url}
                    />
        </Content>
    </ContainerDefault >
);
}

export default MenuCafeteria;