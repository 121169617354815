import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { getFormularioRecorrido } from '../../api/getFormularioRecorrido';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Componentes
import { ContainerDefault } from '../../common/Containers';
import { HeaderSpaceBetween, Title } from '../../common/Header';
import { Content } from '../../common/Containers';
import { FieldContainer, InputContainer, Input, SingleInputContainer, SubmitInput, TooltipContent, TooltipContentMobile } from '../../common/Form';
import { HeaderInput } from '../../common/Header';
import Tooltip from '../../common/Tooltip';

// import Form from '../../components/Form';

const FormularioSolicitudRecorrido = () => {
    const [content, setContent] = useState([]);
    const [recaptcha, setRecaptcha] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        lastNameOne: '',
        lastNameTwo: '',
        phoneNumber: '',
        email: '',
        medicName: '',
        weeks: '',
        visitDate: ''
    });

    const fetchData = async () => {
        try {
            const response = await getFormularioRecorrido();
            setContent(response.data.attributes)
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = () => {
        if (!recaptcha ||
            !formData.name ||
            !formData.lastNameOne ||
            !formData.lastNameTwo ||
            !formData.phoneNumber ||
            !formData.email ||
            !formData.medicName ||
            !formData.weeks ||
            !formData.visitDate) {
            console.log('Error en Google Recaptcha: ', recaptcha)
            alert('Llene todos los campos');
        } else {
            fetch('https://utils.strategix4.com/emails/api/hsda/visit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then(result => {
                    console.log(result);
                    alert('Formulario enviado exitosamente!');
                    window.location.reload();
                })
                .catch(error => {
                    console.log('error', error);
                    alert('Error en enviar el formulario');
                });
        }
    }

    useEffect(() => {
        document.title = "Formulario Solicitud Recorrido - Hospital San Diego de Alcalá";
    }, []);


    return (
        <ContainerDefault>
            <Content>
                <HeaderSpaceBetween
                    style={{ padding: '0 1rem', }}>
                    <Title>Formulario Solicitud Recorrido</Title>
                    <TooltipContentMobile>
                        Llene los campos correspondientes
                    </TooltipContentMobile>
                    <TooltipContent>
                        <Tooltip message='Llene todos los campos correspondientes' />
                    </TooltipContent>
                </HeaderSpaceBetween>
                <Body>
                    <InputContainer>
                        {/* NOMBRE */}
                        <SingleInputContainer
                            style={{ display: content.nombrePaciente ? 'flex' : 'none' }}>
                            <HeaderInput>Nombre(s) del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder='Tu nombre'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <InputContainer>
                        {/* APELLIDO PATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoPaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Paterno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameOne"
                                    name="lastNameOne"
                                    value={formData.lastNameOne}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Paterno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* APELLIDO MATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoMaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Materno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameTwo"
                                    name="lastNameTwo"
                                    value={formData.lastNameTwo}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Materno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>

                    <InputContainer>
                        {/* TELEFONO */}
                        <SingleInputContainer
                            style={{ display: content.telefono ? 'flex' : 'none' }}>
                            <HeaderInput>Télefono Celular</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    placeholder='(662) 123 1234'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* EMAIL */}
                        <SingleInputContainer
                            style={{ display: content.email ? 'flex' : 'none' }}>
                            <HeaderInput>Email</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder='ejemplo@gmail.com'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* SEMANAS GESTACION */}
                        <SingleInputContainer
                            style={{ display: content.semanasGestacion ? 'flex' : 'none' }}>
                            <HeaderInput>Semanas de Gestación</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="weeks"
                                    name="weeks"
                                    value={formData.weeks}
                                    onChange={handleInputChange}
                                    placeholder='0'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Nombre del Medico Tratante */}
                        <SingleInputContainer
                            style={{ display: content.medicoTratante ? 'flex' : 'none' }}>
                            <HeaderInput>Nombre del médico tratante</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="medicName"
                                    name="medicName"
                                    value={formData.medicName}
                                    onChange={handleInputChange}
                                    placeholder='Nombre del médico tratante'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Nombre del Medico Tratante */}
                        <SingleInputContainer
                            style={{ display: content.fechaPropuestaVisita ? 'flex' : 'none' }}>
                            <HeaderInput>Fecha propuesta de visita</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="date"
                                    id="visitDate"
                                    name="visitDate"
                                    value={formData.visitDate}
                                    onChange={handleInputChange}
                                    placeholder='01/01/2024'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <SubmitInput
                        type='button' value="Enviar Formulario" onClick={handleSubmit}
                    />
                    <GoogleReCaptchaProvider reCaptchaKey="6Ld9iM0oAAAAAMt4gxe4mRtS4YHfkJ484Z0CKWLo">
                        <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
                    </GoogleReCaptchaProvider>
                </Body>
            </Content>
        </ContainerDefault>
    );
}

export default FormularioSolicitudRecorrido;

const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '100%',
    padding: '2rem',
    marginTop: '2rem',
    borderRadius: '1.25rem',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
}));