import React, { useState } from 'react';
import { styled, ClickAwayListener, Drawer, Grid } from '@mui/material';
import { navbarItems } from './navbarItems';
import { motion } from 'framer-motion';

//Common
import logoHSDA from '../../images/logotype-hsda.svg';
import { Logo } from '../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <NavbarContainer>
        <div
          style={{
            width: '100%',
            maxWidth: '2000px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderRadius: 100,
          }}
        >
          <Drawer open={menuOpen} anchor='top'>
            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
              <HamburgerContainer>
                {navbarItems.map((item, index) => {
                  return (
                    <HamburgerNavbarItem key={index}>
                      <motion.a
                        key={item.title}
                        title={item.title}
                        href={item.href}
                        whileHover={{ scale: 1.3 }}
                        transition={{ type: 'spring', stiffness: 300 }}
                        style={{
                          textDecoration: 'none',
                          color: index === 6 ? '#F4F8FA' : '#5B8EBB',
                          backgroundColor:
                            index === 6 ? '#395874' : 'transparent',
                          padding: index === 6 ? '10px 35px' : '0',
                          borderRadius: index === 6 ? '1.875rem' : '0',
                          lineHeight: '1.5',
                          fontSize: 16,
                        }}
                      >
                        {item.title}
                      </motion.a>
                    </HamburgerNavbarItem>
                  );
                })}
              </HamburgerContainer>
            </ClickAwayListener>
          </Drawer>

          <a
            href='/'
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <Logo src={logoHSDA} alt='logo' />
          </a>

          <MenuContainer>
            {navbarItems.map((item, index) => {
              return (
                <NavbarItem key={index}>
                  <motion.a
                    key={item.title}
                    title={item.title}
                    href={item.href}
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: 'spring', stiffness: 300 }}
                    style={{
                      textDecoration: 'none',
                      display: 'inline-flex',
                      backgroundColor: index === 6 ? '#395874' : 'transparent',
                      color: index === 6 ? '#F4F8FA' : '#5B8EBB',
                      borderRadius: index === 6 ? '1.875rem' : '0',
                      padding: index === 6 ? '0.3125rem 1.7rem' : '0',
                      lineHeight: '1.5',
                      fontSize: 16,
                    }}
                  >
                    {item.title}
                  </motion.a>
                </NavbarItem>
              );
            })}
            <HamburgerButton
              type='button'
              onClick={() => setMenuOpen(true)}
              title='menu icon'
            >
              <FontAwesomeIcon icon={faBars} />
            </HamburgerButton>
          </MenuContainer>
        </div>
      </NavbarContainer>
    </>
  );
};

export default Navbar;

const NavbarContainer = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  top: '0',
  zIndex: '999',
  padding: '1rem 5rem',
  backgroundColor: theme.palette.light.main,
  //   display: 'grid',
  width: '100%',
  // maxWidth: '2000px',
  //   gridTemplateColumns: '4fr 8fr',
  //   gap: '1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    // gridTemplateColumns: 'repeat(2, 6fr)',
    padding: '1rem 2rem',
  },
}));

const MenuContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const NavbarItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '600',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
}));

const HamburgerButton = styled('button')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '20px',
    color: theme.palette.seablue.main,
    backgroundColor: 'transparent',
    fontSize: '18px',
    border: 'none',
  },
}));

const HamburgerContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  background: theme.palette.light.main,
  padding: '2rem',
}));

const HamburgerNavbarItem = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: '1rem 0',
}));
