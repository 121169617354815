import { Input, SubmitInput } from '../../common/Form';
import { ContainerYCenterCenter } from '../../common/Containers';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContainerDefault } from '../../common/Containers';
import { HeaderFlexStart, Title } from '../../common/Header';
import { Content } from '../../common/Containers';
import useAuth from '../../auth/useAuth';

const Login = () => {
  const location = useLocation();
  const { state } = location;
  const { onLogin } = useAuth();

  const [credentials, setCredentials] = useState({
    identifier: state && state.identifier,
    password: '',
  });

  useEffect(() => {
    document.title = 'Servidor Multimedia - Login';
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    const { identifier, password } = credentials;
    onLogin(identifier, password);
  };

  return (
    <ContainerDefault>
      <Content>
        <HeaderFlexStart style={{ padding: '0 1rem' }}>
          <Title>Login</Title>
        </HeaderFlexStart>
        <ContainerYCenterCenter style={{ color: '#395874' }}>
          <h3>Email: </h3>
          <Input
            style={{
              width: 'auto',
              backgroundColor: '#CDDAE1',
              padding: '.5rem',
            }}
            type='text'
            name='identifier'
            value={credentials.identifier}
            placeholder={state && state.identifier}
            onChange={handleChange}
            readOnly={state ? true : false}
          />
          <h3>Contraseña</h3>
          <Input
            style={{
              width: 'auto',
              backgroundColor: '#CDDAE1',
              padding: '.5rem',
            }}
            type='password'
            name='password'
            value={credentials.password}
            onChange={handleChange}
          />
          <SubmitInput
            style={{ width: 'auto' }}
            type='button'
            value='Iniciar sesión'
            onClick={handleLogin}
          />
        </ContainerYCenterCenter>
      </Content>
    </ContainerDefault>
  );
};

export default Login;
