import useAuth from '../auth/useAuth';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { session } = useAuth();
  const location = useLocation();

  if (!session) {
    // return <Navigate to='/home' replace />;
    return <Navigate to='/servidor-multimedia' replace state={{from: location}}/>;
  }

  return children;
};

export default ProtectedRoute;