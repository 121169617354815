import React, { useEffect, useState } from 'react';
import useAuth from '../../auth/useAuth.js';
import { getPacientes } from '../../api/getPacientes.js';

//Components
import { ContainerDefault } from '../../common/Containers/index.js';
import { Content } from '../../common/Containers/index.js';
import { SubmitInput } from '../../common/Form/index.js';
import { HeaderSpaceBetween, Title } from '../../common/Header/index.js';
import { CardGridMedicos } from '../../common/Card/index.js';
import { Card, TitleCard } from '../../common/Card/index.js';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Pacientes() {
  const { onLogout, session } = useAuth();
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    document.title = 'Pacientes';
  }, []);

  useEffect(() => {
    fetchData(session.jwt);
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async (token) => {
    try {
      const response = await getPacientes(token);
      setPatients(response.data);
      console.log('patients: ', response.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ContainerDefault>
      <Content>
        <HeaderSpaceBetween>
          <Title>Pacientes de {session && session.user.username}</Title>
          <SubmitInput 
            style={{ width: 'auto' }}
            type='button' 
            onClick={onLogout}
            value='Logout'
          />
        </HeaderSpaceBetween>
          <CardGridMedicos
          style={{width: '100%'}}>
          {patients
          .filter((patient) => patient.attributes.email === session.user.email)
          .map((patient, outerIndex) =>
            patient.attributes.patientname.map((patientInfo, innerIndex) => (
              <a
                key={outerIndex + '-' + innerIndex}
                href={patientInfo.link}
                target='_blank'
                rel='noopener noreferrer'
                style={{textDecoration: 'none'}}
              >
                <Card
                >
                  <h1>
                    <FontAwesomeIcon style={{ color: '#395874' }} icon={faVideo} />
                  </h1>
                  <TitleCard>
                    {patientInfo.patientname}
                  </TitleCard>
                </Card>
              </a>
            ))
          )}
        </CardGridMedicos>
      </Content>
    </ContainerDefault>
  );
}

export default Pacientes;
