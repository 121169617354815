import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { getMaternidadSection } from '../../api/getMaternidadSection';

//Common
import {
  Title,
  HeaderSpaceBetween,
  Description,
  Subtitle,
} from '../../common/Header';
import FormattedContent from '../../common/FormattedContent';
import PrimaryButton from '../../common/PrimaryButton';
import { Content, ContainerYCenterCenter } from '../../common/Containers';

const MaternitySection = () => {
  const [content, setContent] = useState([]);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await getMaternidadSection();
      setContent(response.data.attributes);
      setImage(response.data.attributes.image.data[0].attributes);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ImageSection = styled('div')(({ theme }) => ({
    borderRadius: '1.25rem',
    width: '31.89431rem',
    height: '18.75rem',
    backgroundImage: `url(${process.env.REACT_APP_CMS_URL}${image.url})`,
    [theme.breakpoints.down('lg')]: {
      width: '17.5625rem',
      height: '11.50038rem',
    },
  }));

  return (
    <Content>
      {!loading && (
        <>
          <HeaderSpaceBetween>
            <Title>{content.title}</Title>
            <PrimaryButton
              color='#F4F8FA'
              backgroundColor='#9F5A5D'
              hoverBackgroundColor={'rgba(203, 128, 131, 0.60)'}
              hoverColor={'#6E3E40'}
              text={content.button}
              url={content.buttonurl}
            />
          </HeaderSpaceBetween>
          <Body>
            <Description>
              <Subtitle style={{ color: '#9F5A5D' }}>
                {content.subtitle}
              </Subtitle>
              <br />
              <div style={{ textAlign: 'justify' }}>
                <FormattedContent content={content.shortDescription} />
              </div>
            </Description>
            <ContainerYCenterCenter>
              <div style={{ position: 'relative' }}>
                <ImageShadow></ImageShadow>
                <ImageSection />
              </div>
            </ContainerYCenterCenter>
          </Body>
        </>
      )}
    </Content>
  );
};

export default MaternitySection;

const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '2rem',
  padding: '2rem 1rem',
  borderRadius: '20px',
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
  backgroundColor: theme.palette.rose.main,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    // padding: '2rem',
    width: '100%',
  },
}));

const ImageShadow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(203, 128, 131, 0.50)',
  borderRadius: '1.25rem',
  width: '31.89431rem',
  height: '18.75rem',
  [theme.breakpoints.down('lg')]: {
    width: '17.5625rem',
    height: '11.50038rem',
  },
}));
