async function getUnidades() {
    try {
      let requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_CMS_ACCESS_TOKEN}`,
        },
        redirect: 'follow',
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_CMS_URL}/api/hsda-unidades?populate=*`,
        requestOptions,
      );
      return await response.json();
    } catch (error) {
      throw error;
    }
  }
  
  export { getUnidades };