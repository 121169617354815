import React, { useState } from 'react';
import './styles.css';
import { styled } from '@mui/material';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Tooltip = ({message}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ButtonTooltip>      
        <FontAwesomeIcon  icon={faQuestion} />
      </ButtonTooltip>
      <TooltipContent className={`tooltip ${tooltipVisible ? 'visible' : ''}`}>
        {message}
      </TooltipContent>
    </div>
  );
};

export default Tooltip;

const ButtonTooltip = styled('button')(({ theme }) => ({
  padding: '0.5rem .8rem',
  borderRadius: '100%',
  border: 'none',
  zIndex: 999,

  fontFamily: 'Avenir-Black',
  fontSize: '1rem',
  color: '#F4F8FA',
  backgroundColor: '#395874',

  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: '#94A8B2',
  },

  [theme.breakpoints.down('sm')]: {
    display: "none",
  },
}));

const TooltipContent = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '-400px',
  top: 0,
  backgroundColor: '#94A8B2',
  color: '#fff',
  padding: '.5rem',
  paddingRight: '5rem',
  borderRadius: '1rem',
  zIndex: 998, 
  opacity: 0,
  [theme.breakpoints.down('sm')]: {
    left: '0px',
    marginBottom: '2rem',
  },
}));