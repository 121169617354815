import React, { useEffect, useState } from 'react';
import SingleText from '../../components/SingleText';
import { getRequisitosDonacionSangre } from '../../api/getRequisitosDonacionSangre';
import {ContainerDefault} from '../../common/Containers';

const RequisitosDonacionSangre = () => {
    const [content, setContent] = useState([]);

    useEffect(() => {
        document.title = "Requisitos de Donacion de Sangre - Hospital San Diego de Alcalá";
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await getRequisitosDonacionSangre();
            setContent(response.data.attributes);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ContainerDefault>
            <SingleText name="Requisitos para la donación de sangre" attributes={content} />
        </ContainerDefault>
    );
};

export default RequisitosDonacionSangre;