import React, { useEffect } from 'react';
import {ContainerDefault} from '../../common/Containers';
import GridServicios from '../../components/GridServicios';
import { getServicios } from '../../api/getServicios.js';

const Servicios = () => {
    useEffect(() => {
        document.title = "Nuestros Servicios - Hospital San Diego de Alcalá";
    }, []);

    return (
        <ContainerDefault>
            <GridServicios 
            title="Servicios" 
            apiFunction={getServicios}
            />
        </ContainerDefault>
    );
}

export default Servicios;
