import { React } from 'react';
import { styled } from '@mui/material';
import { faSearch, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PrimarySearchBar = (props) => {
    return (
        <Content>
            <StartAndInputContainer>
                <InputContainer>
                    <Input
                        id={props.id || undefined}
                        name={props.name || undefined}
                        type={props.type || "text"}
                        placeholder={props.placeholder || 'Buscar'}
                        onChange={props.onChange || null}
                        autoFocus={props.autoFocus || false}
                        onKeyPress={props.keyPress || null}
                    />
                </InputContainer>
                <StartAdornment>
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </StartAdornment>
            </StartAndInputContainer>
        </Content>
    );
};

export default PrimarySearchBar;

const Content = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.light.main,
    color: theme.palette.pencilgray.main,
    border: "none",
    borderRadius: 30,
    padding: '.9rem 1rem',
    fontFamily: 'Avenir',
    marginRight: '1rem',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('lg')]: {
        width: '85%',
        marginRight: '0',
    },
}));
const StartAndInputContainer = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: theme.palette.blueberry.main,
    width: '100%',
}));
const StartAdornment = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: '1rem',
}));
const InputContainer = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: (props) =>
        props.centerInputText ? "center" : "flex-start",
    width: '100%',

    [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
    },
}));

const Input = styled('input')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: (props) => props.centerInputText ? "center" : "flex-start",
    textAlign: (props) => (props.centerInputText ? "center" : "auto"),
    alignContent: "center",
    color: theme.palette.placeholder.main,
    border: "none",
    outline: "none",
    borderRadius: 10,
    width: "100%",
    backgroundColor: 'transparent',
    fontFamily: 'Avenir',
    fontSize: '1.25rem',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
    },
}));