import React, { useState, useEffect, useCallback } from 'react';

//Common
import { Content } from '../../common/Containers';
import FormattedContent from '../../common/FormattedContent/index.js';
import { CardGridServicios, Card, TitleCard, DescriptionCard, HeaderCardGrid } from '../../common/Card/index.js';
import { HeaderFlexStart, Title } from '../../common/Header/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, styled } from '@mui/material';

import {
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

const GridServicios = ({ apiFunction, title }) => {
  const [content, setContent] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await apiFunction();
      setContent(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [apiFunction]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Content>
      <HeaderFlexStart><Title>{title}</Title></HeaderFlexStart>
      <CardGridServicios >
        {content.map((item) => {
          return (
            <Card key={item.id}>
              <HeaderCardGrid>
                <img alt='' style={{ height: '6.25rem' }} src={process.env.REACT_APP_CMS_URL + item.attributes.image.data.attributes.url} />
                <TitleCard>{item.attributes.title}</TitleCard>
              </HeaderCardGrid>
              <DescriptionCard>
                <FormattedContent content={item.attributes.longDescription} />
              </DescriptionCard>
              <ButtonItem target='_blank' href={item.attributes.buttonurl}>
                <FontAwesomeIcon style={{ display: item.attributes.buttonurl.startsWith('tel:') ? 'block' : 'none' }} icon={faPhone} />
                <div>{item.attributes.button}</div>
              </ButtonItem>
            </Card>
          );
        })}
      </CardGridServicios>
    </Content>
  );
};

export default GridServicios;

const ButtonItem = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '70%',
  padding: '0.625rem 1.875rem',
  // alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '0 1rem',
  borderRadius: '1.875rem',
  color: '#F4F8FA',
  backgroundColor: '#395874',
  fontSize: '1rem',
  textAlign: 'center',
  fontWeight: 500,
  fontFamily: 'Avenir',
  [theme.breakpoints.down('sm')]: {
    fontSize: '.7rem',
    padding: '0.425rem 1.875rem',
    width: '90%',
  },
  '&:hover': {
    backgroundColor: theme.palette.stormyblue.main, // Change background color on hover
},
}))