import logoHSDA from '../../images/logotype-hsda-dark-background.svg';
import { styled, Grid } from '@mui/material';

//FaIcons
import { Logo } from '../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
  ContainerFooterTags,
  ContainerXCenter,
} from '../../common/Containers';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <a
          href='/'
          style={{
            textDecoration: 'none',
            color: 'inherit',
            padding: 20
          }}
        >
          <Logo src={logoHSDA} alt='logo' />
        </a>
        <PagesContainer>
          <ContainerFooterTags>
            <a
              href='/terminosycondiciones'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Términos y Condiciones
            </a>
            <a
              href='/avisoprivacidad'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Aviso Privacidad
            </a>
            <a
              href='/contacto'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Contacto
            </a>
            <a
              href='/formulario-preadmision'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Pre-admisión
            </a>
          </ContainerFooterTags>
          <ContainerFooterTags style={{ marginTop: '1rem' }}>
            <a
              href='/formulario-presupuesto'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Presupuesto
            </a>
            <a
              href='/formulario-solicitud-recorrido'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Solicitud de recorrido
            </a>
            <a
              href='/formulario-credencializacion'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Credencialización
            </a>
            <a
              href='/menucafeteria'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Menú de cafetería
            </a>
          </ContainerFooterTags>
        </PagesContainer>

        <ContainerXCenter style={{ gap: '1rem', padding: 20 }}>
          <a
            href='https://www.facebook.com/hospitalsandiegodealcala/'
            style={{ color: '#fff', fontSize: '1.5em' }}
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href='https://www.instagram.com/hospitalsandiegodealcala/?hl=en'
            style={{ color: '#fff', fontSize: '1.5em' }}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </ContainerXCenter>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  // maxWidth: '2000px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: 0,
  zIndex: '999',
  padding: '1rem 5rem',
  backgroundColor: theme.palette.blueberry.main,
  color: theme.palette.white.main,
  fontSize: '1rem',
  fontFamily: 'Avenir',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    paddingBottom: '2rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem',
  },
}));

const FooterContent = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '2000px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Avenir',
  paddingLeft: '100px',
  paddingRight: '100px',
  paddingTop: 20,
  paddingBottom: 20,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
   
  },
}));
