import { Button, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SecondaryButton = ({ props }) => {
  const ButtonItem = styled(Button)(({ theme }) => ({
    display: 'flex',
    width: props.width,
    padding: '0.625rem 1.875rem',
    // alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '0 1rem',
    borderRadius: '1.875rem',
    color: props.color,
    backgroundColor: props.backgroundColor,
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'Avenir',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
      padding: '0.425rem 1.875rem',
      width: props.widthMobile !== undefined ? props.widthMobile : '90%',
    },
    '&:hover': {
      backgroundColor: theme.palette.stormyblue.main, // Change background color on hover
    },
  }));

  return (
    <ButtonItem target='_blank' href={props.url}>
      <FontAwesomeIcon style={{ display: 'none' }} icon={props.icon} />
      <div>{props.text}</div>
    </ButtonItem>
  );
};

export default SecondaryButton;
