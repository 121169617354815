import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { getMedicos } from '../../api/getMedicos';
import { getEspecialidades } from '../../api/getEspecialidades';

//Common
import PrimarySearchBar from '../../common/PrimarySearchBar';
import { Title, HeaderSearchBar } from '../../common/Header';
import { Content } from '../../common/Containers';
import { CardGridMedicos } from '../../common/Card/index.js';
import ModalMedicosCard from '../ModalMedicosCard/index.js';
import './styles.css';
import theme from '../../styles/index.js';

function FilterMedicos() {
  const [searchValue, setSearchValue] = useState('');
  const [medicos, setMedicos] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getMedicos();
      setMedicos(response.data);

      const response2 = await getEspecialidades();
      setEspecialidades(response2.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredMedicos = medicos.filter((medico) => {
    // Search by title or especialidad
    return (
      medico.attributes.title
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      medico.attributes.especialidad
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  });

  const phrase = 'Busca por nombre o especialidad';
  const placeholder =
    phrase.length < 25 ? `${phrase}` : `${window.innerWidth <= theme.breakpoints.values.lg? phrase.substring(0, 25) : phrase.substring(0, 50)}...`;

  return (
    <>
      <Content>
        <HeaderSearchBar>
          <Title>Médicos</Title>
          <PrimarySearchBar
            value={searchValue}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        </HeaderSearchBar>
        <Body>
          <FilterContainer>
            <FilterContainerTitle>Especialidades</FilterContainerTitle>
            <FilterContainerTags>
              <p
                onClick={() => setSearchValue('')}
                style={{ color: searchValue === '' ? '#5B8EBB' : '#F4F8FA' }}
              >
                Todas
              </p>
              {especialidades
                .slice()
                .sort((a, b) =>
                  a.attributes.title.localeCompare(b.attributes.title)
                )
                .map((item) => (
                  <p
                    key={item.attributes.title}
                    onClick={() => setSearchValue(item.attributes.title)}
                    style={{
                      color:
                        searchValue === item.attributes.title
                          ? '#5B8EBB'
                          : '#F4F8FA',
                    }}
                  >
                    {item.attributes.title}
                  </p>
                ))}
            </FilterContainerTags>
          </FilterContainer>
          <CardGridMedicos>
            {filteredMedicos.map((item, index) => (
              <ModalMedicosCard key={index} item={item}></ModalMedicosCard>
            ))}
          </CardGridMedicos>
        </Body>
      </Content>
    </>
  );
}
export default FilterMedicos;

const Body = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'grid',
  gridTemplateColumns: '1fr 3fr',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  gap: '2rem',
  padding: '1rem 0',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    padding: '1rem 2rem',
  },
}));

const FilterContainer = styled('div')(({ theme }) => ({
  height: '100%',
  borderRadius: '1.25rem',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  gap: '1.6875rem',
  backgroundColor: theme.palette.blueberry.main,
  color: theme.palette.light.main,
  fontFamily: 'Avenir',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));
const FilterContainerTitle = styled('div')(({ theme }) => ({
  fontSize: '1.875rem',
  fontWeight: '800',
  margin: '1rem',
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.5rem',
  },
}));
const FilterContainerTags = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '900',
  margin: '1rem',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.3rem',
  },
}));
