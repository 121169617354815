import React, { useEffect } from 'react';
// Componentes
import NosotrosSection from '../../components/NosotrosSection';
import AnimatedCardSection from '../../components/AnimatedCardSection';
import { getAmenidades } from '../../api/getAmenidades';
import {ContainerDefault} from '../../common/Containers';

const Nosotros = () => {
    useEffect(() => {
        document.title = "Nosotros - Hospital San Diego de Alcalá";
    }, []);
    return (
        <ContainerDefault>
            <NosotrosSection name="Nosotros"/>
            <AnimatedCardSection
                name="Amenidades"
                title="¿Ya conoces nuestras amenidades?"
                button="Ir a amenidades"
                url="/"
                displayButton= {true}
                apiFunction={getAmenidades} />
        </ContainerDefault>
    );
}

export default Nosotros;