import { React } from 'react';
import { Grid, styled } from '@mui/material';
import FormattedContent from '../../common/FormattedContent';

//Common
import { Title } from '../../common/Header';
import {Content} from '../../common/Containers';

function SingleText({ attributes }) {
    return (
        <>
            <Content>
                <Header>
                    <Title>{attributes.title}</Title>
                </Header>
                <Body>
                    <FormattedContent content={attributes.description}/>
                </Body>
            </Content>
        </>
    );
}

export default SingleText;

const Header = styled(Grid)(({ theme }) => ({
    overflow: 'hidden',
    width: '90%',
    gap: '2rem',
    paddingBottom: '2rem',
}));
const Body = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '90%',
    gap: '2rem',
    paddingBottom: '2rem',
    fontSize: '1rem',
    fontWeight: '500',
    fontFamily: 'Avenir',
    color: theme.palette.blueberry.main,
}));