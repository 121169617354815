import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { getProximamenteSection } from '../../api/getProximamenteSection';

//Common
import { Content } from '../../common/Containers';
import FormattedContent from '../../common/FormattedContent';
import { Description, HeaderFlexStart, Title } from '../../common/Header';

const ProximamenteSection = () => {
  const [content, setContent] = useState([]);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await getProximamenteSection();
      setContent(response.data.attributes);
      setImage(response.data.attributes.image.data[0].attributes);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ImageSection = styled('div')(({ theme }) => ({
    borderRadius: '2rem',
    width: '40rem',
    height: '25rem',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${process.env.REACT_APP_CMS_URL}${image.url})`,
    [theme.breakpoints.down('lg')]: {
      width: '17.5625rem',
      height: '11.50038rem',
    },
  }));

  return (
    <Content>
      {!loading && (
        <Body>
          <GridItem>
            <HeaderFlexStart>
              <Title>{content.title}</Title>
            </HeaderFlexStart>
            <Description style={{ textAlign: 'justify' }}>
              <FormattedContent content={content.description} />
            </Description>
          </GridItem>

          <GridImage>
            <ImageSection />
          </GridImage>
        </Body>
      )}
    </Content>
  );
};

export default ProximamenteSection;

const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '2rem',
  padding: '0',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    // padding: '2rem',
    width: '100%',
  },
}));

const GridItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Avenir',
  fontSize: '16px',
  color: theme.palette.pencilgray.main,
  textAlign: 'justify',
}));
const GridImage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  fontFamily: 'Avenir',
}));
