import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import theme from './styles/index';
import ArticuloNoticia from './pages/ArticuloNoticia';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import AuthProvider from './auth/authProvider';
import Chatbot from './components/Chatbot';
import Contacto from './pages/Contacto';
import Especialidades from './pages/Especialidades';
import Footer from './components/Footer';
import FormularioCredencializacion from './pages/FormularioCredencializacion';
import FormularioPreadmision from './pages/FormularioPreadmision';
import FormularioPresupuestos from './pages/FormularioPresupuestos';
import FormularioSolicitudRecorrido from './pages/FormularioSolicitudRecorrido';
import Home from './pages/Home';
import Login from './pages/Login';
import Maternidad from './pages/Maternidad';
import Medicos from './pages/Medicos';
import MenuCafeteria from './pages/MenuCafeteria';
import Nosotros from './pages/Nosotros';
import Noticias from './pages/Noticias';
import Navbar from './components/Navbar';
import Pacientes from './pages/Pacientes';
import ProtectedRoute from './routes/protectedRoutes';
import RequisitosDonacionSangre from './pages/RequisitosDonacionSangre';
import Servicios from './pages/Servicios';
import ServidorMultimedia from './pages/ServidorMultimedia';
import TerminosyCondiciones from './pages/TerminosyCondiciones';

function App() {
  return (

    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.light.main,
      }}
    >
      <BrowserRouter>
        <Navbar />
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/avisoprivacidad' element={<AvisoPrivacidad />} />
            <Route path='/contacto' element={<Contacto />} />
            <Route path='/especialidades' element={<Especialidades />} />
            <Route path='/formulario-preadmision' element={<FormularioPreadmision />} />
            <Route path='/formulario-solicitud-recorrido' element={<FormularioSolicitudRecorrido />} />
            <Route path='/formulario-presupuesto' element={<FormularioPresupuestos />} />
            <Route path='/formulario-credencializacion' element={<FormularioCredencializacion />} />
            <Route path='/login' element={<Login />} />
            <Route path='/maternidad' element={<Maternidad />} />
            <Route path='/medicos' element={<Medicos />} />
            <Route path='/menucafeteria' element={<MenuCafeteria />} />
            <Route path='/noticias' element={<Noticias />} />
            <Route path='/noticias/*' element={<ArticuloNoticia />} />
            <Route path='/nosotros' element={<Nosotros />} />
            <Route path='/requisitos-donacion-sangre' element={<RequisitosDonacionSangre />} />
            <Route path='/servicios' element={<Servicios />} />
            <Route path='/servidor-multimedia' element={<ServidorMultimedia />} />
            <Route path='/terminosycondiciones' element={<TerminosyCondiciones />} />
            <Route
              path='/pacientes'
              element={
                <ProtectedRoute>
                  <Pacientes />
                </ProtectedRoute>
              }
            />
            <Route path='*' element={<Home />} />
          </Routes>
        </AuthProvider>
        <div style={{ zIndex: 9999 }}>
          <Chatbot />
        </div>
        <Footer />
      </BrowserRouter>
    </div>

  );
}

export default App;
