import React, { useState, useEffect } from 'react';
import { getUnidades } from '../../api/getUnidades.js';
import { styled } from '@mui/material';

//FaIcons
import { faFacebook, faInstagram, } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

//Common
import { Content } from '../../common/Containers/index.js';
import { HeaderFlexStart, Title, Description } from '../../common/Header/index.js';
import { Card, CardGridUnidades, DescriptionCard, TitleCard } from '../../common/Card/index.js';
import FormattedContent from '../../common/FormattedContent/index.js';
import SecondaryButton from '../../common/SecondaryButton/index.js';
const UnidadesSection = () => {
    const [content, setContent] = useState([])
    const fetchData = async () => {
        try {
            const response = await getUnidades();
            setContent(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const ImageSection = styled('img')(({ theme }) => ({
        borderRadius: '100%',
        height: '8rem',
        [theme.breakpoints.down('lg')]: {
            height: '5rem',
        },
    }));
    const MapSection = styled('img')(({ theme }) => ({
        borderRadius: '10%',
        height: '12rem',
        [theme.breakpoints.down('lg')]: {
            height: '8rem',
        },
    }));

    return (
        <Content>
            <HeaderFlexStart>
                <Title>Cuida tu cuerpo y mente</Title>
                <Description style={{ textAlign: 'justify' }}>En el Hospital San Diego de Alcalá nos preocupamos por tu bienestar integral, por eso te ofrecemos dos unidades especializadas en cuidar tanto tu salud física como emocional. La Unidad Metabólica te brinda un enfoque personalizado y multidisciplinario para tratar enfermedades metabólicas como la diabetes y la obesidad. Mientras tanto, nuestra Unidad de Bienestar Emocional te ofrece apoyo y tratamiento para trastornos emocionales como la ansiedad y la depresión. Confía en nuestro equipo de profesionales altamente capacitados y experimentados para cuidar de ti en todos los aspectos. Ven y descubre cómo podemos ayudarte a lograr una vida más saludable y feliz.</Description>
            </HeaderFlexStart>

            <CardGridUnidades>
                {content.map((item) => {
                    return (
                        <Card key={item.id}>
                            <ImageSection
                                alt=''
                                src={process.env.REACT_APP_CMS_URL + item.attributes.logo.data.attributes.url}
                            />
                            <TitleCard style={{ color: "#5B5B5B" }}>{item.attributes.title}</TitleCard>
                            <DescriptionCard
                                style={{ textAlign: 'center', width: '80%' }}
                            >
                                <FormattedContent content={item.attributes.description} />
                            </DescriptionCard>
                            <MapSection
                                alt=''
                                src={process.env.REACT_APP_CMS_URL + item.attributes.urlImage}
                            />
                            <SecondaryButton
                                props={{
                                    color: '#F4F8FA',
                                    backgroundColor: `${item.attributes.colorboton}`,
                                    icon: faPhone,
                                    url: `tel:${item.attributes.urlTelefono}`,
                                    text: `Llamar al ${item.attributes.urlTelefono}`,
                                    width: '70%'
                                }}
                            />
                            <SecondaryButton
                                props={{
                                    color: '#F4F8FA',
                                    backgroundColor: `${item.attributes.colorboton}`,
                                    icon: faFacebook,
                                    url: `${item.attributes.urlFacebook}`,
                                    text: 'Visitar la página de Facebook',
                                    width: '70%'
                                }}
                            />
                            <SecondaryButton
                                props={{
                                    color: '#F4F8FA',
                                    backgroundColor: `${item.attributes.colorboton}`,
                                    icon: faInstagram,
                                    url: `${item.attributes.urlInstagram}`,
                                    text: 'Visitar la página de Instagram',
                                    width: '70%'
                                }}
                            />
                        </Card>
                    );
                })}
            </CardGridUnidades>

        </Content>
    );
};

export default UnidadesSection;