import React, { useState, useEffect } from 'react';
import { getEspecialidades } from '../../api/getEspecialidades';

// Common
import { Title, HeaderSearchBar } from '../../common/Header';
import { Content } from '../../common/Containers';
import PrimarySearchBar from '../../common/PrimarySearchBar';
import {
  CardGridMedicos
} from '../../common/Card/index.js';
import ModalEspecialidadesCard from '../ModalEspecialidadesCard/index.js';
import './styles.css';
import theme from '../../styles/index.js';

function FilterEspecialidades() {
  const [searchValue, setSearchValue] = useState('');
  const [content, setContent] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getEspecialidades();
      setContent(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  // Filtering the content based on the searchValue
  const filteredContent = content.filter((item) =>
    item.attributes.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  const phrase = 'Busca una especialidad'
  const placeholder =
  phrase.length < 25 ? `${phrase}` : `${window.innerWidth <= theme.breakpoints.values.lg? phrase.substring(0, 25) : phrase.substring(0, 50)}...`;

  return (
    <>
      <Content>
        <HeaderSearchBar>
          <Title>Especialidades</Title>
          <PrimarySearchBar
            value={searchValue}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        </HeaderSearchBar>
        <CardGridMedicos>
          {filteredContent.map((item, index) => (
            <ModalEspecialidadesCard key={index} item={item}></ModalEspecialidadesCard>
          ))}
        </CardGridMedicos>
      </Content>
    </>
  );
}

export default FilterEspecialidades;
