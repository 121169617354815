import React, { useState, useEffect, useCallback } from 'react';

//Common
import { Content } from '../../common/Containers';
import FormattedContent from '../../common/FormattedContent/index.js';
import { CardGrid, Card, TitleCard, DescriptionCard, HeaderCardGrid } from '../../common/Card/index.js';
import { HeaderFlexStart, Title } from '../../common/Header/index.js';

const GridBasic = ({ apiFunction, title, columns, fractions }) => {
    const [content, setContent] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const response = await apiFunction();
            setContent(response.data);
        } catch (error) {
            console.error(error);
        }
    }, [apiFunction]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Content>
            <HeaderFlexStart><Title>{title}</Title></HeaderFlexStart>
            <CardGrid style={{ gridTemplateColumns: `repeat(${columns}, ${fractions}fr)` }}>
                {content.map((item) => {
                    return (
                        <Card
                            style={{ backgroundColor: '#F4F8FA' }}
                            key={item.id}>
                            <HeaderCardGrid >
                                <img alt='' style={{ height: '6.25rem' }} src={process.env.REACT_APP_CMS_URL + item.attributes.image.data.attributes.url}  />
                                <TitleCard>{item.attributes.title}</TitleCard>
                            </HeaderCardGrid>
                            <DescriptionCard>
                                <FormattedContent content={item.attributes.description} />
                            </DescriptionCard>
                        </Card>
                    );
                })}
            </CardGrid>
        </Content>
    );
};

export default GridBasic;