import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { getNoticias } from '../../api/getNoticias';


//Common
import { Title, HeaderSpaceBetween } from '../../common/Header';
import { CardGridNoticias } from '../../common/Card/index.js';
import { Content, ContainerDefault } from '../../common/Containers';
import { Card, TitleCard, DescriptionCard } from '../../common/Card/index.js';

const Noticias = () => {
    const [content, setContent] = useState([])
    const fetchData = async () => {
        try {
            const response = await getNoticias();
            setContent(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        document.title = "Noticias - Hospital San Diego de Alcalá";
    }, []);

    const ImageSection = styled('img')(({ theme }) => ({
        height: '12rem',
        [theme.breakpoints.down('lg')]: {
            height: '7rem',
        },
    }));

    return (
        <ContainerDefault>

            <Content>
                <HeaderSpaceBetween>
                    <Title>Noticias y Eventos</Title>
                </HeaderSpaceBetween>

                <CardGridNoticias>
                    {content.map((item) => {
                        return (
                            <Card key={item.id}>
                                <ImageSection
                                    alt=''
                                    src={process.env.REACT_APP_CMS_URL + item.attributes.image.data.attributes.url}
                                />
                                <TitleCard>{item.attributes.title}</TitleCard>
                                <DescriptionCard>
                                    {item.attributes.shortDescription} 
                                    <ATag 
                                    href={`/noticias/${encodeURIComponent(item.attributes.title)}`}>leer más...</ATag>
                                </DescriptionCard>
                            </Card>
                        );
                    })}
                </CardGridNoticias>
            </Content>
        </ContainerDefault>

    );
};

export default Noticias;

const ATag = styled('a')(({ theme }) => ({
    textDecoration: 'none',
    fontWeight: 'bolder',
    color: theme.palette.blueberry.main,
}));