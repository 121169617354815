import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { getFormularioPreadmision } from '../../api/getFormularioPreadmision';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Componentes
import { ContainerDefault } from '../../common/Containers';
import { HeaderSpaceBetween, Title } from '../../common/Header';
import { Content } from '../../common/Containers';
import { FieldContainer, InputContainer, Input, SingleInputContainer, SubmitInput, TooltipContent, TooltipContentMobile } from '../../common/Form';
import Tooltip from '../../common/Tooltip';
import { HeaderInput } from '../../common/Header';

const FormularioPreadmision = () => {
    const [content, setContent] = useState([]);
    const [recaptcha, setRecaptcha] = useState(false);

    const fetchData = async () => {
        try {
            const response = await getFormularioPreadmision();
            setContent(response.data.attributes)
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const [data, setData] = useState({
        name: '',
        lastNameOne: '',
        lastNameTwo: '',
        phoneNumber: '',
        email: '',
    });
    const [credential, setCredential] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('Seleccione un archivo');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setCredential(file);
        if (file) {
            setSelectedFileName(file.name);
        } else {
            setSelectedFileName('Seleccione un archivo');
        }
    };

    const handleSubmit = () => {
        var formdata = new FormData();
        const dataForm = JSON.stringify(data)
        formdata.append('data', dataForm);
        formdata.append('credential', credential)
        console.log(formdata);
        var requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: formdata,
            redirect: 'follow'
        };
        if (!recaptcha ||
            !data.name ||
            !data.lastNameOne ||
            !data.lastNameTwo ||
            !data.phoneNumber ||
            !data.email) {
            console.log('Error en Google Recaptcha: ', recaptcha)
        } else {
            fetch("https://utils.strategix4.com/emails/api/hsda/pre-admition", requestOptions) // Adjust the URL to your server's endpoint
                .then(response => response.text())
                .then(result => {
                    console.log(result);
                    alert('Formulario enviado exitosamente!');
                    window.location.reload();
                })
                .catch(error => {
                    console.log('error', error);
                    alert('Error en enviar el formulario');
                });
        }
    };

    useEffect(() => {
        document.title = "Formulario Preadmisión - Hospital San Diego de Alcalá";
    }, []);
    return (
        <ContainerDefault>
            <Content>
                <HeaderSpaceBetween
                    style={{ padding: '0 1rem', }}>
                    <Title>Formulario Preadmisión</Title>
                    <TooltipContentMobile>
                        Llene los campos correspondientes
                    </TooltipContentMobile>
                    <TooltipContent>
                        <Tooltip message='Llene todos los campos correspondientes' />
                    </TooltipContent>
                </HeaderSpaceBetween>
                <Body>
                    <InputContainer>
                        {/* NOMBRE */}
                        <SingleInputContainer
                            style={{ display: content.nombrePaciente ? 'flex' : 'none' }}>
                            <HeaderInput>Nombre(s) del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={data.name}
                                    onChange={handleInputChange}
                                    placeholder='Tu nombre'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <InputContainer>
                        {/* APELLIDO PATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoPaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Paterno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameOne"
                                    name="lastNameOne"
                                    value={data.lastNameOne}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Paterno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* APELLIDO MATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoMaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Materno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameTwo"
                                    name="lastNameTwo"
                                    value={data.lastNameTwo}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Materno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>

                    <InputContainer>
                        {/* TELEFONO */}
                        <SingleInputContainer
                            style={{ display: content.telefono ? 'flex' : 'none' }}>
                            <HeaderInput>Télefono Celular</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={data.phoneNumber}
                                    onChange={handleInputChange}
                                    placeholder='(662) 123 1234'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* EMAIL */}
                        <SingleInputContainer
                            style={{ display: content.email ? 'flex' : 'none' }}>
                            <HeaderInput>Email</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={data.email}
                                    onChange={handleInputChange}
                                    placeholder='ejemplo@gmail.com'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <InputContainer>
                        {/* ARCHIVO PDF */}
                        <SingleInputContainer
                            style={{ display: content.archivoPdf ? 'flex' : 'none' }}>
                            <HeaderInput>Credencial de elector</HeaderInput>
                            <FieldContainer style={{ backgroundColor: '#5B8EBB' }}>
                                <CustomInputLabel>
                                    {selectedFileName}
                                    <CustomFileInput
                                        type="file"
                                        id="credential"
                                        name="credential"
                                        onChange={handleFileChange}
                                    />
                                </CustomInputLabel>
                            </FieldContainer>
                        </SingleInputContainer>


                    </InputContainer>
                    <SubmitInput
                        type='button' value="Enviar Formulario" onClick={handleSubmit}
                    />
                    <GoogleReCaptchaProvider reCaptchaKey="6Ld9iM0oAAAAAMt4gxe4mRtS4YHfkJ484Z0CKWLo">
                        <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
                    </GoogleReCaptchaProvider>
                </Body>
            </Content>
        </ContainerDefault>
    );
}

export default FormularioPreadmision;

const Body = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '100%',
    padding: '2rem',
    marginTop: '2rem',
    borderRadius: '1.25rem',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
}));

const CustomFileInput = styled('input')(({ theme }) => ({
    display: 'none', // Hide the default file input
}));
 

const CustomInputLabel = styled('label')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) => (props.centerInputText ? 'center' : 'flex-start'),
    textAlign: (props) => (props.centerInputText ? 'center' : 'auto'),
    alignContent: 'center',
    border: 'none',
    color: '#F4F8FA',
    outline: 'none',
    borderRadius: 10,
    width: '100%',
    backgroundColor: 'transparent',
    fontFamily: 'Avenir',
    fontSize: '1rem',
    fontWeight: '900',
    cursor: 'pointer',
}));