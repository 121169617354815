import React, {useEffect} from 'react';

//Components
import {ContainerDefault} from '../../common/Containers';
import FilterEspecialidades from '../../components/FilterEspecialidades';

const Especialidades = () => {
    useEffect(() => {
        document.title = "Especialidades - Hospital San Diego de Alcalá";
    }, []);
    return (
        <ContainerDefault>
            <FilterEspecialidades name="Especialidades"/>
        </ContainerDefault>
    );
}

export default Especialidades;