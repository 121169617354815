export const navbarItems = [
  {
    title: 'Inicio',
    href: '/',
  },
  {
    title: 'Nosotros',
    href: '/nosotros',
  },
  {
    title: 'Servicios',
    href: '/servicios',
  },
  {
    title: 'Especialidades',
    href: '/especialidades',
  },
  {
    title: 'Médicos',
    href: '/medicos',
  },
  {
    title: 'Membresías',
    href: 'https://membresias.hospitalsandiegodealcala.com/',
  },
  {
    title: 'Contacto',
    href: '/contacto',
  },
];
