import { styled } from '@mui/material';

const InputContainer = styled('div')(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(2, 6fr)`,
    gap: '0 4rem',
    margin: '0 2rem',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.625rem 1.875rem',
    },
}));


const SingleInputContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5rem',
    alignItems: 'flex-start',
    justifyContent: 'center',
}));

const Input = styled('input')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: (props) => props.centerInputText ? "center" : "flex-start",
    textAlign: (props) => (props.centerInputText ? "center" : "auto"),
    alignContent: "center",
    border: "none",
    outline: "none",
    borderRadius: 10,
    width: "100%",
    backgroundColor: 'transparent',
    fontFamily: 'Avenir',
    fontSize: '1rem',
    fontWeight: '900',
}));

const TextArea = styled('textarea')(({ theme }) => ({
    display: "flex",
    resize: 'none',
    justifyContent: (props) => props.centerInputText ? "center" : "flex-start",
    textAlign: (props) => (props.centerInputText ? "center" : "auto"),
    alignContent: "center",
    border: "none",
    outline: "none",
    borderRadius: 10,
    width: "100%",
    backgroundColor: 'transparent',
    fontFamily: 'Avenir',
    fontSize: '1rem',
    fontWeight: '900',
}));

const SubmitInput = styled('input')(({ theme }) => ({
    display: 'flex',
    marginTop: '2rem',
    width: '100%',
    padding: '0.625rem 1.875rem',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1.25rem',
    borderRadius: '1.875rem',
    color: "#F4F8FA",
    backgroundColor: "#395874",
    fontSize: '16px',
    fontWeight: 900,
    fontFamily: 'Avenir',
    textAlign: 'center',
    border: 'none',  '&:hover': {
      backgroundColor: theme.palette.stormyblue.main, // Change background color on hover
  },
}));

const FieldContainer = styled('div')(({ theme }) => ({
    display: "flex",
    backgroundColor: theme.palette.light.main,
    justifyContent: (props) =>
      props.centerInputText ? "center" : "flex-start",
    width: '90%',
    borderRadius: '3.75rem',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
    padding: '1rem 2rem',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
  }));

const SelectInput = styled('select')(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: (props) => props.centerInputText ? "center" : "flex-start",
  textAlign: (props) => (props.centerInputText ? "center" : "auto"),
  alignContent: "center",
  border: "none",
  outline: "none",
  borderRadius: 10,
  width: "100%",
  backgroundColor: 'transparent',
  fontFamily: 'Avenir',
  fontSize: '1rem',
  fontWeight: '900',  
}));

const OptionContainer = styled('option')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.white.main,
  padding: '1rem 0',
  fontFamily: 'Avenir',
  fontSize: '1rem',
  fontWeight: '900',  
}));


const TooltipContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
      display: "none",
  },
}));

const TooltipContentMobile = styled('div')(({ theme }) => ({
  fontFamily: 'Avenir',
  backgroundColor: '#94A8B2',
  textAlign: 'center',
  color: '#fff',
  padding: '.5rem 2rem',
  borderRadius: '1rem',
  [theme.breakpoints.up('sm')]: {
      display: "none",
  },
}));
export {
    FieldContainer,
    InputContainer, 
    Input, 
    OptionContainer, 
    SingleInputContainer,
    SubmitInput,
    SelectInput,
    TextArea,
    TooltipContent,
    TooltipContentMobile
};