import React from 'react';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import theme from '../../styles/index';

// import { getFaq } from '../../api/getFaq';
// import { useState, useEffect } from 'react';
const chatTheme = {
  background: '#f5f8fb',
  fontFamily: 'Avenir',
  headerBgColor: theme.palette.blueberry.main,
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: theme.palette.seablue.main,
  botFontColor: '#fff',
  userBubbleColor: theme.palette.blueberry.main,
  userFontColor: '#fff',
};

const Chatbot = () => {
  // const [content, setContent] = useState([]);
  // const fetchData = async () => {
  //   try {
  //     const response = await getFaq();
  //     setContent(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const steps = [
    {
      id: '1',
      message: '¡Hola! ¿cómo te puedo ayudar?',
      trigger: '2',
      hideInput: true,
    },
    {
      id: '2',
      options: [
        // { value: 1, label: content[1].attributes.question , trigger: '3' },
        {
          value: 1,
          label: '¿Cómo puedo hacer una cita médica en el hospital?',
          trigger: '6',
        },
        {
          value: 2,
          label: '¿Cuáles son los horarios de visita?',
          trigger: '7',
        },
        { value: 3, label: '¿Qué servicios ofrece el hospital?', trigger: '8' },
        {
          value: 4,
          label:
            '¿Qué servicios de apoyo están disponibles para los pacientes y sus familias?',
          trigger: '9',
        },
        {
          value: 5,
          label:
            '¿Mi póliza de Seguro de Gastos Médicos aplica para mi atención en Hospital San Diego de Alcalá? ',
          trigger: '10',
        },
        {
          value: 6,
          label: '¿Cómo puedo solicitar un presupuesto para mi cirugía? ',
          trigger: '11',
        },
        {
          value: 7,
          label:
            'Soy médico y quiero programar un procedimiento quirúrgico, ¿a dónde me puedo comunicar? ',
          trigger: '12',
        },
        {
          value: 8,
          label: '¿Cuáles son los métodos de pago aceptados? ',
          trigger: '13',
        },
        {
          value: 9,
          label:
            '¿Qué incluye la membresía de Hospital San Diego de Alcalá, qué incluye y cómo puedo adquirirla?',
          trigger: '14',
        },
        {
          value: 10,
          label: '¿Cómo puedo credencializarme en el Hospital? ',
          trigger: '15',
        },
      ],
    },
    {
      id: '3',
      message: '¿Necesitas ayuda en algo más?',
      trigger: '4',
    },
    {
      id: '4',
      options: [
        { value: 1, label: 'Sí', trigger: '2' },
        { value: 2, label: 'No', trigger: '5' },
      ],
    },
    {
      id: '5',
      message: '¡Muchas gracias! Excelente día',
    },
    {
      id: '6',
      message:
        'Para hacer una cita médica en nuestro hospital, puede llamar al número de teléfono (662) 198 9850, extensión 416 o visitar nuestra sección de citas médicas en línea. Allí encontrará un formulario en el que puede completar la información de su cita. Agendar aquí',
      trigger: '3',
    },

    {
      id: '7',
      message:
        'Horario de visita para pacientes hospitalizados es de 7:00 a.m. a 21:00 horas. Horario de urgencias: 24 horas. Horario de farmacia: 24 horas. Horario de laboratorio: 24 horas. Horario de imagenología:. Horario de cafetería: Lunes a Viernes de 7:00 a.m. a 18:30 horas | Sábado y Domingo de 9:00 a.m. a 16:30 horas.',
      trigger: '3',
    },
    {
      id: '8',
      message:
        'Ofrecemos una amplia variedad de servicios médicos, desde atención primaria hasta especialidades como: ginecología, pediatría, ortopedia, urología, otorrinolaringología, neurocirugía, medicina interna, cirugía bariátrica, nutrición, algología, cirugía plástica, cirugía general, endoscopía, geriatría, anestesiología, etc. También contamos con servicios de laboratorio, radiología, farmacia, Unidad de Cuidados Intensivos, Unidad de Cuidados Intensivos Neonatal, banco de sangre, sala de parto, cuneros.',
      trigger: '3',
    },

    {
      id: '9',
      message:
        'Ofrecemos una variedad de servicios de apoyo, como servicios de asesoramiento, ejecutiva de seguros de gastos médicos, oratorio y para una valoración y seguimiento adecuado, contamos con la Unidad de Bienestar Emocional San Diego de Alcalá. Puedes obtener más información sobre estos servicios en nuestro sitio web, vía whatsapp (662) 275 4524, o a nuestra línea directa: (662) 198 9850. ',
      trigger: '3',
    },
    {
      id: '10',
      message:
        'Actualmente contamos con las siguientes aseguradoras en red: AXA, GNP, ATLAS, SURA, SEGUROS MONTERREY, ALLIANZ, MEDICAVIAL, QUALITAS, MAPFRE, PREVEM, BX+, PROSESO, GENERAL DE SALUD, ARCÁNGEL, ANA SEGUROS, HIR, LA LATINO, INBURSA, PLAN SEGURO.Si deseas conocer más información y/o resolver alguna duda te puedes comunicar con nuestra ejecutiva de seguros, quien te asesorará en todo el proceso de tu trámite: (662) 198 9850 EXT. 104 (662) 368 7226  ',
      trigger: '3',
    },
    {
      id: '11',
      message:
        'Puedes comunicarte con nuestra coordinadora de presupuestos quien te brindará la información completa de todo lo que incluye el procedimiento y sus costos. Teléfono: (662) 198 9850 EXT. 607 ',
      trigger: '3',
    },
    {
      id: '12',
      message:
        'Correo: programacion@hospitalsandiegodealcala.com. De lunes a viernes: celular (662) 191 6067 y teléfono (662) 198 9850 ext. 200. De 7 a.m. a 21 hrs. 9am a 5pm - Servicio de whats app (incluir si es posible link o QR que redireccione al celular. Horario de atención para procedimientos de urgencia: 21 hrs. A 7:00 a.m.',
      trigger: '3',
    },
    {
      id: '13',
      message: 'Efectivo, transferencia, tarjeta (débito y crédito). ',
      trigger: '3',
    },
    {
      id: '14',
      message: 'Información no disponible de momento aquí</a>',
      trigger: '3',
    },
    {
      id: '15',
      component: (
        <p
          style={{
            // margin: '18px 18px 18px 0',
            padding: '10px',
          }}
        >
          Puedes llenar el siguiente{' '}
          <a
            style={{
              textDecoration: 'none',
              color: theme.palette.seablue.main,
              fontWeight: 'bold',
            }}
            href='/formulario-credencializacion'
          >
            formulario de credencialización (haz click aquí)
          </a>
        </p>
      ),
      trigger: '3',
    },
  ];

  const config = {
    floating: true,
  };

  return (
    <ThemeProvider theme={chatTheme}>
      <ChatBot
        headerTitle='Chat'
        steps={steps}
        placeholder={''}
        hideSubmitButton={true}
        {...config}
      />
    </ThemeProvider>
  );
};

export default Chatbot;
