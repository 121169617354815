import React, { useState, useEffect } from 'react';
import { getMaternidadSection } from '../../api/getMaternidadSection';
import { getMaternidadInstalaciones } from '../../api/getMaternidadInstalaciones';

//Common
import { Title, Description } from '../../common/Header';
import FormattedContent from '../../common/FormattedContent';
import { Content, ContainerYSpaceBetween, ContainerYCenterFlexend, ContainerGrid } from '../../common/Containers';
import SecondaryButton from '../../common/SecondaryButton';
import GridBasic from '../../components/GridBasic';
import { ContainerDefault } from '../../common/Containers';


const Maternidad = () => {
    const [content, setContent] = useState([])
    const [image, setImage] = useState('');

    const fetchData = async () => {
        try {
            const response = await getMaternidadSection();
            setContent(response.data.attributes);
            setImage(response.data.attributes.image.data[0].attributes);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        document.title = "Maternidad - Hospital San Diego de Alcalá";
    }, []);

    return (
        <ContainerDefault style={{ backgroundColor: '#FFE3E4' }}>
            <Content>
                <ContainerGrid
                    style={{
                        padding: '2rem 0',
                        gridTemplateColumns: 'repeat(2, 6fr)',
                    }}>
                    <ContainerYSpaceBetween>
                        <Title
                            style={{ color: '#9F5A5D' }}>
                            {content.subtitle}
                        </Title>
                        <Description style={{ textAlign: 'justify', paddingBottom: '2rem' }}>
                            <br />
                            <FormattedContent content={content.description} />
                        </Description>
                        <SecondaryButton
                            props={{
                                color: "#F4F8FA",
                                backgroundColor: "#9F5A5D",
                                text: "Agenda una visita",
                                url: "/formulario-solicitud-recorrido",
                            }}
                        />
                    </ContainerYSpaceBetween>

                    <ContainerYCenterFlexend>
                        <div style={{ position: 'relative' }}>
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(203, 128, 131, 0.50)',
                                borderRadius: '1.25rem',
                                width: '39.4375rem',
                                height: '22.0625rem',
                            }}>
                            </div>
                            <img style={{
                                borderRadius: '1.25rem',
                                width: '39.4375rem',
                                height: '22.0625rem',
                            }}
                                src={process.env.REACT_APP_CMS_URL + image.url} alt={image.alternativeText} />
                        </div>
                    </ContainerYCenterFlexend>
                </ContainerGrid>
            </Content >
            <GridBasic
                apiFunction={getMaternidadInstalaciones}
                title={"Conoce nuestras instalaciones"}
                columns={2}
                fractions={6} />
        </ContainerDefault>
    );
}

export default Maternidad;