import React, { useEffect } from 'react';

//Components
import HeroSection from '../../components/HeroSection';
import MaternitySection from '../../components/MaternidadSection';
import ProximamenteSection from '../../components/ProximamenteSection';
import UnidadesSection from '../../components/UnidadesSection';
import NoticiasSection from '../../components/NoticiasSection';
import { ContainerDefault } from '../../common/Containers';
import { getServicios } from '../../api/getServicios';
import AnimatedCardSection from '../../components/AnimatedCardSection';
import theme from '../../styles';
const Home = () => {
  useEffect(() => {
    document.title = 'Inicio - Hospital San Diego de Alcalá';
  }, []);
  return (
    <ContainerDefault>
      <HeroSection />
      <AnimatedCardSection
        name='Services'
        title='¿Ya conoces nuestros servicios?'
        button='Ir a servicios médicos'
        url='/servicios'
        buttonColor={theme.palette.light.main}
        buttonBackgroundColor={theme.palette.blueberry.main}
        apiFunction={getServicios}
      />
      <MaternitySection />
      <ProximamenteSection />
      <UnidadesSection />
      <NoticiasSection />
    </ContainerDefault>
  );
};

export default Home;
