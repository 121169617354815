import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { getFormularioPresupuesto } from '../../api/getFormularioPresupuesto';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


// Componentes
import { ContainerDefault } from '../../common/Containers';
import { HeaderSpaceBetween, Title } from '../../common/Header';
import { Content } from '../../common/Containers';
import Tooltip from '../../common/Tooltip';
import { FieldContainer, InputContainer, Input, SingleInputContainer, SubmitInput, TooltipContent, TooltipContentMobile } from '../../common/Form';
import { HeaderInput } from '../../common/Header';

const FormularioPresupuestos = () => {
    const [content, setContent] = useState([]);
    const [recaptcha, setRecaptcha] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        lastNameOne: '',
        lastNameTwo: '',
        phoneNumber: '',
        email: '',
        medicName: '',
        medicPhone: '',
        procedurenName: '',
        surgeryDuration: '',
        anesthesiaType: '',
        hospitalizationTime: '',
        comments: '',
        requestType: ''
    });

    const fetchData = async () => {
        try {
            const response = await getFormularioPresupuesto();
            setContent(response.data.attributes)
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        if (!recaptcha ||
            !formData.name ||
            !formData.lastNameOne ||
            !formData.lastNameTwo ||
            !formData.phoneNumber ||
            !formData.email ||
            !formData.medicName ||
            !formData.medicPhone ||
            !formData.procedurenName ||
            !formData.surgeryDuration ||
            !formData.anesthesiaType ||
            !formData.hospitalizationTime ||
            !formData.comments ||
            !formData.requestType) {
            console.log('Error en Google Recaptcha: ', recaptcha)
            alert('Llene todos los campos');
        } else {
            fetch('https://utils.strategix4.com/emails/api/hsda/estimate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then((response) => {
                    if (response.ok) {
                        alert('Form submitted successfully');
                        window.location.reload();
                    } else {
                        alert('Form submission failed');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    useEffect(() => {
        document.title = "Formulario Presupuestos - Hospital San Diego de Alcalá";
    }, []);


    return (
        <ContainerDefault>
            <Content>
                <HeaderSpaceBetween
                    style={{ padding: '0 1rem', }}>
                    <Title>Formulario de presupuestos</Title>
                    <TooltipContentMobile>
                        Llene los campos correspondientes
                    </TooltipContentMobile>
                    <TooltipContent>
                        <Tooltip message='Llene todos los campos correspondientes' />
                    </TooltipContent>
                </HeaderSpaceBetween>
                <Body>
                    <InputContainer>
                        {/* NOMBRE */}
                        <SingleInputContainer
                            style={{ display: content.nombrePaciente ? 'flex' : 'none' }}>
                            <HeaderInput>Nombre(s) del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder='Tu nombre'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <InputContainer>
                        {/* APELLIDO PATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoPaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Paterno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameOne"
                                    name="lastNameOne"
                                    value={formData.lastNameOne}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Paterno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* APELLIDO MATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoMaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Materno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameTwo"
                                    name="lastNameTwo"
                                    value={formData.lastNameTwo}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Materno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>

                    <InputContainer>
                        {/* TELEFONO */}
                        <SingleInputContainer
                            style={{ display: content.telefono ? 'flex' : 'none' }}>
                            <HeaderInput>Télefono Celular</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    placeholder='(662) 123 1234'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* EMAIL */}
                        <SingleInputContainer
                            style={{ display: content.email ? 'flex' : 'none' }}>
                            <HeaderInput>Email</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder='ejemplo@gmail.com'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Nombre Medico Cirujano */}
                        <SingleInputContainer
                            style={{ display: content.nombreMedicoCirujano ? 'flex' : 'none' }}>
                            <HeaderInput>Nombre completo médico cirujano</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="medicName"
                                    name="medicName"
                                    value={formData.medicName}
                                    onChange={handleInputChange}
                                    placeholder='Nombre del médico cirujano'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Telefono Medico Cirujano */}
                        <SingleInputContainer
                            style={{ display: content.telefonoMedicoCirujano ? 'flex' : 'none' }}>
                            <HeaderInput>Telefono médico cirujano</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="medicPhone"
                                    name="medicPhone"
                                    value={formData.medicPhone}
                                    onChange={handleInputChange}
                                    placeholder='(662) 123 1234'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Procedimiento Medico */}
                        <SingleInputContainer
                            style={{ display: content.nombreProcedimiento ? 'flex' : 'none' }}>
                            <HeaderInput>Procedimiento Médico</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="procedurenName"
                                    name="procedurenName"
                                    value={formData.procedurenName}
                                    onChange={handleInputChange}
                                    placeholder='Procedimiento Médico'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Duracion Cirugia */}
                        <SingleInputContainer
                            style={{ display: content.duracionCirugia ? 'flex' : 'none' }}>
                            <HeaderInput>Duracion de la cirugía (horas)</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="surgeryDuration"
                                    name="surgeryDuration"
                                    value={formData.surgeryDuration}
                                    onChange={handleInputChange}
                                    placeholder='0'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Tipo de Anestesia */}
                        <SingleInputContainer
                            style={{ display: content.tipoAnestesia ? 'flex' : 'none' }}>
                            <HeaderInput>Tipo de anestesia</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="anesthesiaType"
                                    name="anesthesiaType"
                                    value={formData.anesthesiaType}
                                    onChange={handleInputChange}
                                    placeholder='Tipo de anestesia'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Tiempo de Hospitalizacion */}
                        <SingleInputContainer
                            style={{ display: content.tiempoHospitalizacion ? 'flex' : 'none' }}>
                            <HeaderInput>Tiempo de Hospitalizacion (días)</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="hospitalizationTime"
                                    name="hospitalizationTime"
                                    value={formData.hospitalizationTime}
                                    onChange={handleInputChange}
                                    placeholder='0'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Comentarios */}
                        <SingleInputContainer
                            style={{ display: content.comentarios ? 'flex' : 'none' }}>
                            <HeaderInput>Comentarios</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="comments"
                                    name="comments"
                                    value={formData.comments}
                                    onChange={handleInputChange}
                                    placeholder='Escriba comentarios adicionales'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Tipo de solicitud */}
                        <SingleInputContainer
                            style={{ display: content.tipoSolicitud ? 'flex' : 'none' }}>
                            <HeaderInput>Tipo de Solicitud</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="requestType"
                                    name="requestType"
                                    value={formData.requestType}
                                    onChange={handleInputChange}
                                    placeholder='Tipo de solicitud'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <SubmitInput
                        type='button' value="Enviar Formulario" onClick={handleSubmit}
                    />
                    <GoogleReCaptchaProvider reCaptchaKey="6Ld9iM0oAAAAAMt4gxe4mRtS4YHfkJ484Z0CKWLo">
                        <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
                    </GoogleReCaptchaProvider>
                </Body>
            </Content>
        </ContainerDefault>
    );
}

export default FormularioPresupuestos;

const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '100%',
    padding: '2rem',
    marginTop: '2rem',
    borderRadius: '1.25rem',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
}));