import React, { useState } from 'react';
import { Card, NameCard, TagCard } from '../../common/Card/index.js';
import theme from '../../styles/index.js';
import Modal from 'react-modal';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Description,
  HeaderFlexStart,
  Subtitle,
} from '../../common/Header/index.js';
import {
  Cell,
  GrayRow,
  HeaderTable,
  TableDefault,
  WhiteRow,
} from '../../common/Table/index.js';
import ubicacioncontacto from '../../images/ubicacioncontacto.png';
import SecondaryButton from '../../common/SecondaryButton/index.js';
import { styled } from '@mui/material';
import { ContainerYCenterCenter } from '../../common/Containers/index.js';

function ModalMedicosCard(props) {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const modalStyles = {
    overlay: { backgroundColor: 'rgba(0,0,0,0.7)', zIndex: 1000 },
    content: {
      width: window.innerWidth > 900 ? '80%' : '100%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      borderRadius: 30,
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
    },
  };

  return (
    <Card
      style={{
        minWidth: '100%',
      }}
      onClick={toggleModal}
    >
      <CardContainer>
        <NameCard>{props.item.attributes.title}</NameCard>
        <TagCard style={{ textAlign: 'center' }}>
          {props.item.attributes.especialidad}
        </TagCard>
        <TagCard
          style={{
            display: props.item.attributes.especialidadAdicional
              ? 'block'
              : 'none',
            textAlign: 'center',
          }}
        >
          {props.item.attributes.especialidadAdicional}
        </TagCard>
      </CardContainer>
      <Modal
        isOpen={showModal}
        onRequestClose={() => toggleModal()}
        style={modalStyles}
        ariaHideApp={false}
        contentLabel='Create Event Modal'
      >
        <ContainerYCenterCenter>
          <BodyModal>
            <span className='close' onClick={toggleModal}>
              <FontAwesomeIcon
                style={{ color: 'white', fontSize: '2rem' }}
                icon={faXmark}
              />
            </span>
            <InformacionContactoContainer>
              <HeaderFlexStart style={{ gap: '0', padding: '1rem' }}>
                <Subtitle>{props.item.attributes.title}</Subtitle>
                <Description>{props.item.attributes.especialidad}</Description>
              </HeaderFlexStart>
              <TableDefault>
                <HeaderTable>
                  <Cell colSpan={2}>Horarios</Cell>
                </HeaderTable>
                <tbody>
                  <WhiteRow>
                    <Cell>Lunes</Cell>
                    <Cell>8 AM - 5 PM</Cell>
                  </WhiteRow>
                  <GrayRow>
                    <Cell>Martes</Cell>
                    <Cell>8 AM - 5 PM</Cell>
                  </GrayRow>
                  <WhiteRow>
                    <Cell>Miercoles</Cell>
                    <Cell>8 AM - 5 PM</Cell>
                  </WhiteRow>
                  <GrayRow>
                    <Cell>Jueves</Cell>
                    <Cell>8 AM - 5 PM</Cell>
                  </GrayRow>
                  <WhiteRow>
                    <Cell>Viernes</Cell>
                    <Cell>8 AM - 5 PM</Cell>
                  </WhiteRow>
                  <GrayRow>
                    <Cell>Sabado</Cell>
                    <Cell>8 AM - 5 PM</Cell>
                  </GrayRow>
                  <WhiteRow>
                    <Cell>Domingo</Cell>
                    <Cell>8 AM - 5 PM</Cell>
                  </WhiteRow>
                </tbody>
              </TableDefault>
            </InformacionContactoContainer>
            <InformacionContactoContainer>
              <ImageSection2 alt='' src={ubicacioncontacto} />
              <Description>
                Tiene su consultorio en San Diego de Alcala
              </Description>
              <SecondaryButton
                props={{
                  color: '#F4F8FA',
                  backgroundColor: '#395874',
                  text: 'Agendar cita',
                  url: '/contacto',
                }}
              />
            </InformacionContactoContainer>
          </BodyModal>
        </ContainerYCenterCenter>
      </Modal>
    </Card>
  );
}

export default ModalMedicosCard;

const BodyModal = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '90%',
  height: '60%',
  padding: '2rem',
  marginTop: '2rem',
  borderRadius: '1.25rem',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const InformacionContactoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.blueberry.main,
  fontSize: '1.4rem',
  fontWeight: '400',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    width: 'auto',
  },
}));

const ImageSection2 = styled('div')(({ theme }) => ({
  borderRadius: '1.25rem',
  width: '28.125rem',
  height: '23rem',
  backgroundImage: `url(${ubicacioncontacto})`,
  margin: '2rem 0',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    height: '11.3em',
  },
}));

const CardContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    width: '100%',
  },
}));
