import { Button, styled } from '@mui/material';

const PrimaryButton = ({
  backgroundColor,
  color,
  hoverBackgroundColor,
  hoverColor,
  text,
  url,
}) => {
  const ButtonSection = styled(Button)(({ theme }) => ({
    display: 'inline-flex',
    padding: '0.625rem 1.875rem 0.5rem',
    alignItems: 'flex-start',
    gap: '0.625rem',
    borderRadius: '1.875rem',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: color || 'white',
    backgroundColor: backgroundColor,
    fontWeight: 900,
    fontFamily: 'Avenir',
    margin: 0,
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: '0.5rem 1.5rem',
    },
    '&:hover': {
      backgroundColor: hoverBackgroundColor || theme.palette.seablue.main, // Change background color on hover
      color: hoverColor || null, // Change background color on hover
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: '0.5rem 1.5rem',
    },
  }));

  return <ButtonSection href={url}>{text}</ButtonSection>;
};

export default PrimaryButton;
