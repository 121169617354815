import React, { useState, useEffect } from 'react';
import { getNosotrosSection } from '../../api/getNosotrosSection.js';
//Common
import { Title, HeaderFlexStart, Description } from '../../common/Header/index.js';
import { CardGrid, Card, DescriptionCard, TitleCard } from '../../common/Card/index.js';
import {Content} from '../../common/Containers/index.js';
import VideoContainer from '../../common/VideoContainer/index.js';
import video from '../../videos/san-diego-de-alcala-intro.mp4';
import FormattedContent from '../../common/FormattedContent/index.js';

function NosotrosSection() {
    const [content, setContent] = useState([])
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [image3, setImage3] = useState('');

    const fetchData = async () => {
        try {
            const response = await getNosotrosSection();
            setContent(response.data.attributes);
            setImage1(response.data.attributes.card_1_image.data[0].attributes);
            setImage2(response.data.attributes.card_2_image.data[0].attributes);
            setImage3(response.data.attributes.card_3_image.data[0].attributes);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Content>
            <HeaderFlexStart>
                <Title>{content.title}</Title>
                <Description> <FormattedContent content={content.description} /> </Description>
            </HeaderFlexStart>
            <VideoContainer src={video} />
            <CardGrid style={{ gridTemplateColumns: `repeat(3, 4fr)` }}>
                <Card>
                    <img style={{ borderRadius: '100%', height: '6.25rem', }} alt='' src={process.env.REACT_APP_CMS_URL + image1.url} />
                    <TitleCard>{content.card_1_title}</TitleCard>
                    <DescriptionCard style={{ textAlign: 'center' }}><FormattedContent content={content.card_1_description} /></DescriptionCard>
                </Card>
                <Card>
                    <img style={{ borderRadius: '100%', height: '6.25rem', }} alt='' src={process.env.REACT_APP_CMS_URL + image2.url} />
                    <TitleCard>{content.card_2_title}</TitleCard>
                    <DescriptionCard style={{ textAlign: 'center' }}><FormattedContent content={content.card_2_description} /></DescriptionCard>
                </Card>
                <Card>
                    <img style={{ borderRadius: '100%', height: '6.25rem', }} alt='' src={process.env.REACT_APP_CMS_URL + image3.url} />
                    <TitleCard>{content.card_3_title}</TitleCard>
                    <DescriptionCard style={{ textAlign: 'center' }}><FormattedContent content={content.card_3_description} /></DescriptionCard>
                </Card>
            </CardGrid>
        </Content>
    )
};

export default NosotrosSection;