import React, { useEffect, useState } from 'react';
import { getUsers } from '../../api/getUsers';
import { useNavigate } from 'react-router-dom';

//Components
import { ContainerDefault } from '../../common/Containers';
import { HeaderFlexStart, Title } from '../../common/Header';
import { Content } from '../../common/Containers';
import { CardGridMedicos } from '../../common/Card';
import { Card, TitleCard } from '../../common/Card/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';

const ServidorMultimedia = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Servidor Multimedia';
  }, []);

  const fetchData = async () => {
    try {
      const response = await getUsers();
      setUsers(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCardClick = (identifier) => {
    navigate('/login', { state: { identifier } });
  };

  return (
    <ContainerDefault>
      <Content>
        <HeaderFlexStart style={{ padding: '1rem' }}>
          <Title>Servidor Multimedia</Title>
        </HeaderFlexStart>
        <CardGridMedicos
         style={{width: '100%'}}>
          {users.map((item, index) => (
            <Card
              key={index}
              onClick={() => handleCardClick(item.email)}
            >
              <h1>
                <FontAwesomeIcon style={{ color: '#395874' }} icon={faFolder} />
              </h1>
              <TitleCard>{item.username}</TitleCard>
            </Card>
          ))}
        </CardGridMedicos>
      </Content>
    </ContainerDefault>
  );
};

export default ServidorMultimedia;
