import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { getFormularioCredencializacion } from '../../api/getFormularioCredencializacion';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Componentes
import { ContainerDefault } from '../../common/Containers';
import { HeaderSpaceBetween, Title } from '../../common/Header';
import { Content } from '../../common/Containers';
import { FieldContainer, InputContainer, Input, SingleInputContainer, SubmitInput, TooltipContent, TooltipContentMobile } from '../../common/Form';
import { HeaderInput } from '../../common/Header';
import Tooltip from '../../common/Tooltip';

const FormularioCredencializacion = () => {
    const [content, setContent] = useState([]);
    const [recaptcha, setRecaptcha] = useState(false);

    const fetchData = async () => {
        try {
            const response = await getFormularioCredencializacion();
            setContent(response.data.attributes)
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const [data, setData] = useState({
        name: '',
        lastNameOne: '',
        lastNameTwo: '',
        phoneNumber: '',
        email: '',
        curp: '',
        birthDate: '',
        fiscalAdress: '',
        officeAddress: '',
        specialty: '',
        additionalSpecialty: '',
        gmpCertificate: '',
        spCertificate: '',
        councilIdentificationNo: ''
    });

    const [gt, setGt] = useState(null);
    const [selectedGtName, setSelectedGtName] = useState('Seleccione un archivo');
    const [tme, setTme] = useState(null);
    const [selectedTmeName, setSelectedTmeName] = useState('Seleccione un archivo');
    const [cmg, setCmg] = useState(null);
    const [selectedCmgName, setSelectedCmgName] = useState('Seleccione un archivo');
    const [ppsps, setPpsps] = useState(null);
    const [selectedPpspsName, setSelectedPpspsName] = useState('Seleccione un archivo');
    const [ccsc, setCcsc] = useState(null);
    const [selectedCcscName, setSelectedCcscName] = useState('Seleccione un archivo');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleFileGtChange = (event) => {
        const file = event.target.files[0];
        setGt(file);
        if (file) {
            setSelectedGtName(file.name);
        } else {
            setSelectedGtName('Seleccione un archivo');
        }
    };

    const handleFileTmeChange = (event) => {
        const file = event.target.files[0];
        setTme(file);
        if (file) {
            setSelectedTmeName(file.name);
        } else {
            setSelectedTmeName('Seleccione un archivo');
        }
    };

    const handleFileCmgChange = (event) => {
        const file = event.target.files[0];
        setCmg(file);
        if (file) {
            setSelectedCmgName(file.name);
        } else {
            setSelectedCmgName('Seleccione un archivo');
        }
    };

    const handleFilePpspsChange = (event) => {
        const file = event.target.files[0];
        setPpsps(file);
        if (file) {
            setSelectedPpspsName(file.name);
        } else {
            setSelectedPpspsName('Seleccione un archivo');
        }
    };

    const handleFileCcscChange = (event) => {
        const file = event.target.files[0];
        setCcsc(file);
        if (file) {
            setSelectedCcscName(file.name);
        } else {
            setSelectedCcscName('Seleccione un archivo');
        }
    };

    const handleSubmit = () => {
        var formdata = new FormData();
        const dataForm = JSON.stringify(data)
        formdata.append('data', dataForm);
        formdata.append('gt', gt);
        formdata.append('tme', tme);
        formdata.append('cmg', cmg);
        formdata.append('ppsps', ppsps);
        formdata.append('ccsc', ccsc);
        console.log(formdata);
        var requestOptions = {
            method: 'POST',
            headers: {
                // 'Content-Type': 'multipart/form-data', 
                Accept: 'application/json',
            },
            body: formdata,
            redirect: 'follow'
        };
        if (!recaptcha ||
            !data.name ||
            !data.lastNameOne ||
            !data.lastNameTwo ||
            !data.phoneNumber ||
            !data.email ||
            !data.curp ||
            !data.birthDate ||
            !data.fiscalAdress ||
            !data.officeAddress ||
            !data.specialty ||
            !data.gmpCertificate ||
            !data.spCertificate ||
            !data.councilIdentificationNo) {
            console.log('Error en Google Recaptcha: ', recaptcha)
            alert('Llene todos los campos');
        } else {
            fetch('https://utils.strategix4.com/emails/api/hsda/credentialing', requestOptions) // Adjust the URL to your server's endpoint
                .then(response => response.text())
                .then(result => {
                    console.log(result);
                    alert('Formulario enviado exitosamente!');
                    window.location.reload();
                })
                .catch(error => {
                    console.log('error', error);
                    alert('Error en enviar el formulario');
                });
        }
    };

    useEffect(() => {
        document.title = "Formulario Credencialización - Hospital San Diego de Alcalá";
    }, []);


    return (
        <ContainerDefault>
            <Content>
                <HeaderSpaceBetween
                    style={{ padding: '0 1rem', }}>
                    <Title>Formulario Credencialización</Title>
                    <TooltipContentMobile>
                        Llene los campos correspondientes
                    </TooltipContentMobile>
                    <TooltipContent>
                        <Tooltip message='Llene todos los campos correspondientes' />
                    </TooltipContent>
                </HeaderSpaceBetween>
                <Body>
                    <InputContainer>
                        {/* NOMBRE */}
                        <SingleInputContainer
                            style={{ display: content.nombrePaciente ? 'flex' : 'none' }}>
                            <HeaderInput>Nombre(s) del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={data.name}
                                    onChange={handleInputChange}
                                    placeholder='Tu nombre'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <InputContainer>
                        {/* APELLIDO PATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoPaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Paterno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameOne"
                                    name="lastNameOne"
                                    value={data.lastNameOne}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Paterno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* APELLIDO MATERNO */}
                        <SingleInputContainer
                            style={{ display: content.apellidoMaterno ? 'flex' : 'none' }}>
                            <HeaderInput>Apellido Materno del paciente</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="lastNameTwo"
                                    name="lastNameTwo"
                                    value={data.lastNameTwo}
                                    onChange={handleInputChange}
                                    placeholder='Apellido Materno'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>

                    <InputContainer>
                        {/* TELEFONO */}
                        <SingleInputContainer
                            style={{ display: content.telefono ? 'flex' : 'none' }}>
                            <HeaderInput>Télefono Celular</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={data.phoneNumber}
                                    onChange={handleInputChange}
                                    placeholder='(662) 123 1234'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* EMAIL */}
                        <SingleInputContainer
                            style={{ display: content.email ? 'flex' : 'none' }}>
                            <HeaderInput>Email</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={data.email}
                                    onChange={handleInputChange}
                                    placeholder='ejemplo@gmail.com'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* CURP */}
                        <SingleInputContainer
                            style={{ display: content.curp ? 'flex' : 'none' }}>
                            <HeaderInput>CURP</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="curp"
                                    name="curp"
                                    value={data.curp}
                                    onChange={handleInputChange}
                                    placeholder='Tu CURP aquí'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Fecha de nacimiento*/}
                        <SingleInputContainer
                            style={{ display: content.fechaNacimiento ? 'flex' : 'none' }}>
                            <HeaderInput>Fecha de nacimiento</HeaderInput>
                            <FieldContainer>
                                <Input
                                    // type="date"
                                    type="date"
                                    id="birthDate"
                                    name="birthDate"
                                    value={data.birthDate}
                                    onChange={handleInputChange}
                                    placeholder='dd/mm/yyyy'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Domicilio Fiscal */}
                        <SingleInputContainer
                            style={{ display: content.domicilioFiscal ? 'flex' : 'none' }}>
                            <HeaderInput>Domicilio Fiscal</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="fiscalAdress"
                                    name="fiscalAdress"
                                    value={data.fiscalAdress}
                                    onChange={handleInputChange}
                                    placeholder='Tu domicilio fiscal'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                        {/* Domicilio Oficina */}
                        <SingleInputContainer
                            style={{ display: content.domicilioConsultorio ? 'flex' : 'none' }}>
                            <HeaderInput>Dirección Consultorio</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="officeAddress"
                                    name="officeAddress"
                                    value={data.officeAddress}
                                    onChange={handleInputChange}
                                    placeholder='El domicilio de tu consultorio'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Especialidad */}
                        <SingleInputContainer
                            style={{ display: content.especialidad ? 'flex' : 'none' }}>
                            <HeaderInput>Especialidad</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="specialty"
                                    name="specialty"
                                    value={data.specialty}
                                    onChange={handleInputChange}
                                    placeholder='Tu especialidad'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Especialidad Adicional*/}
                        <SingleInputContainer
                            style={{ display: content.especialidadAdicional ? 'flex' : 'none' }}>
                            <HeaderInput>Especialidad Adicional</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="additionalSpecialty"
                                    name="additionalSpecialty"
                                    value={data.additionalSpecialty}
                                    onChange={handleInputChange}
                                    placeholder='Otra especialidad'
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Cedula Medico General*/}
                        <SingleInputContainer
                            style={{ display: content.cedulaMedicoGeneral ? 'flex' : 'none' }}>
                            <HeaderInput>Cédula profesional de médico general</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="gmpCertificate"
                                    name="gmpCertificate"
                                    value={data.gmpCertificate}
                                    onChange={handleInputChange}
                                    placeholder='Tu cédula médico general'
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Cedula profesional especialista*/}
                        <SingleInputContainer
                            style={{ display: content.cedulaProfesionalEspecialista ? 'flex' : 'none' }}>
                            <HeaderInput>Cédula profesional especialista</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="spCertificate"
                                    name="spCertificate"
                                    value={data.spCertificate}
                                    onChange={handleInputChange}
                                    placeholder='Tu cédula especialista'
                                />
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* Cedula profesional especialista*/}
                        <SingleInputContainer
                            style={{ display: content.identificacionConsejo ? 'flex' : 'none' }}>
                            <HeaderInput>Número de identificación del consejo</HeaderInput>
                            <FieldContainer>
                                <Input
                                    type="text"
                                    id="councilIdentificationNo"
                                    name="councilIdentificationNo"
                                    value={data.councilIdentificationNo}
                                    onChange={handleInputChange}
                                    placeholder='Tu número de identificación del consejo'
                                />
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <InputContainer>
                        {/* TITULO GENERAL */}
                        <SingleInputContainer
                            style={{ display: content.tituloGeneral ? 'flex' : 'none' }}>
                            <HeaderInput>Subir título general</HeaderInput>
                            <FieldContainer style={{ backgroundColor: '#5B8EBB' }}>
                                <CustomInputLabel>
                                    {selectedGtName}
                                    <CustomFileInput
                                        type="file"
                                        id="gt"
                                        name="gt"
                                        onChange={handleFileGtChange}
                                    />
                                </CustomInputLabel>
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* TITULO MEDICO ESPECIALISTA */}
                        <SingleInputContainer
                            style={{ display: content.tituloMedicoEspecialista ? 'flex' : 'none' }}>
                            <HeaderInput>Subir título médico especialista</HeaderInput>
                            <FieldContainer style={{ backgroundColor: '#5B8EBB' }}>
                                <CustomInputLabel>
                                    {selectedTmeName}
                                    <CustomFileInput
                                        type="file"
                                        id="tme"
                                        name="tme"
                                        onChange={handleFileTmeChange}
                                    />
                                </CustomInputLabel>
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* CEDULA MEDICO GENERAL */}
                        <SingleInputContainer
                            style={{ display: content.tituloMedicoEspecialista ? 'flex' : 'none' }}>
                            <HeaderInput>Subir cédula médico general</HeaderInput>
                            <FieldContainer style={{ backgroundColor: '#5B8EBB' }}>
                                <CustomInputLabel>
                                    {selectedCmgName}
                                    <CustomFileInput
                                        type="file"
                                        id="cmg"
                                        name="cmg"
                                        onChange={handleFileCmgChange}
                                    />
                                </CustomInputLabel>
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* CEDULA PROFESIONAL ESPECIALISTA */}
                        {/* <SingleInputContainer
                            style={{ display: content.cedulaProfesionalEspecialista ? 'flex' : 'none' }}>
                            <HeaderInput>Archivo PDF</HeaderInput>
                            <FieldContainer style={{ backgroundColor: '#5B8EBB' }}>
                                <Input
                                    type="file"
                                    id="cmg"
                                    name="cmg"
                                    value={formData.cmg}
                                    onChange={handleInputChange}
                                    placeholder='Subir cédula médico especialista'
                                    required
                                />
                            </FieldContainer>
                        </SingleInputContainer> */}

                        {/* PERMISO ESTATAL */}
                        <SingleInputContainer
                            style={{ display: content.permisoEstatal ? 'flex' : 'none' }}>
                            <HeaderInput>Subir permiso para ejercer en el estado como médico general y especialista</HeaderInput>
                            <FieldContainer style={{ backgroundColor: '#5B8EBB' }}>
                                <CustomInputLabel>
                                    {selectedPpspsName}
                                    <CustomFileInput
                                        type="file"
                                        id="ppsps"
                                        name="ppsps"
                                        onChange={handleFilePpspsChange}
                                    />
                                </CustomInputLabel>
                            </FieldContainer>
                        </SingleInputContainer>

                        {/* CERTIFICADO VIGENTE */}
                        <SingleInputContainer
                            style={{ display: content.certificadoVigente ? 'flex' : 'none' }}>
                            <HeaderInput>Subir certificado vigente del colegio de especialidad al que perteneces</HeaderInput>
                            <FieldContainer style={{ backgroundColor: '#5B8EBB' }}>
                                <CustomInputLabel>
                                    {selectedCcscName}
                                    <CustomFileInput
                                        type="file"
                                        id="ccsc"
                                        name="ccsc"
                                        onChange={handleFileCcscChange}
                                    />
                                </CustomInputLabel>
                            </FieldContainer>
                        </SingleInputContainer>
                    </InputContainer>
                    <SubmitInput
                        type='button' value="Enviar Formulario" onClick={handleSubmit}
                    />
                    <GoogleReCaptchaProvider reCaptchaKey="6Ld9iM0oAAAAAMt4gxe4mRtS4YHfkJ484Z0CKWLo">
                        <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
                    </GoogleReCaptchaProvider>
                </Body>
            </Content>
        </ContainerDefault>
    );
}

export default FormularioCredencializacion;

const Body = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '100%',
    padding: '2rem',
    marginTop: '2rem',
    borderRadius: '1.25rem',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
}));

const CustomFileInput = styled('input')(({ theme }) => ({
    display: 'none', // Hide the default file input
}));

const CustomInputLabel = styled('label')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) => (props.centerInputText ? 'center' : 'flex-start'),
    textAlign: (props) => (props.centerInputText ? 'center' : 'auto'),
    alignContent: 'center',
    border: 'none',
    color: '#F4F8FA',
    outline: 'none',
    borderRadius: 10,
    width: '100%',
    backgroundColor: 'transparent',
    fontFamily: 'Avenir',
    fontSize: '1rem',
    fontWeight: '900',
    cursor: 'pointer',
}));