import { Grid, styled, keyframes } from '@mui/material';

const CardGrid = styled(Grid)(({ theme, columns, fractions }) => ({
  display: 'grid',
  gap: '2rem',
  width: '100%',
  gridTemplateColumns: `repeat(${columns}, ${fractions}fr)`,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: `repeat(1)`,
  },
}));

const CardGridNoticias = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gap: '2rem',
  width: '100%',
  gridTemplateColumns:  `repeat(3,4fr)` ,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: `repeat(1, 12fr)`,
  },
}));

const CardGridServicios = styled(Grid)(({ theme}) => ({
  display: 'grid',
  gap: '2rem',
  width: '100%',
  gridTemplateColumns: `repeat(2, 6fr)`,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: `repeat(1, 12fr)`,
  },
}));

const CardGridMedicos = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gap: '2rem',
  gridTemplateColumns: `repeat(4, 3fr)`,
  justifyContent: 'center',
  margin: '0 auto', 
  padding: 20,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `repeat(2, 4fr)`,
    padding: 0,
  },
}));

const CardGridUnidades = styled(Grid)(({ theme }) => ({

  display: 'grid',
  gap: '2rem',
  gridTemplateColumns: `repeat(2, 6fr)`,
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: `repeat(1, 12fr)`,
  },
}));

const Card = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  borderRadius: '2rem',
  padding: '2rem',
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15);',
  fontFamily: 'Avenir',
  color: theme.palette.pencilgray.main,
  [theme.breakpoints.down('lg')]: {
    padding: '1rem',
  },
}));
const AnimatedCard = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '2rem',
  padding: '1rem',
  minWidth: '20%',
  minHeigth: '90%',
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15);',
  fontFamily: 'Avenir',
  color: theme.palette.pencilgray.main,
  margin: '0 1.6%',
  [theme.breakpoints.up('sm')]: {
    minHeight: '30rem',
    minWidth: '18.75rem',
    gap: '1.8rem',
    padding: '2rem',
  },
}));

const HeaderCardGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 6fr',
  gap: '1.8rem',
  width: '100%',
}));

const TitleCard = styled('div')(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: '900',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.pencilgray.main,
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '.72rem',
  },
}));
const NameCard = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '900',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Avenir',
  color: theme.palette.pencilgray.main,
  [theme.breakpoints.down('lg')]: {
    fontSize: '.82rem',
  },
}));

const DescriptionCard = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  height: '100%',
  fontFamily: 'Avenir',
  color: theme.palette.pencilgray.main,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '.6rem',
  },
}));
const ImageCard = styled('img')(({ theme }) => ({
  height: '4.375rem',
  [theme.breakpoints.up('xl')]: {
    height: '6.56rem',
  },
}));

const ProfilePicCard = styled('img')(({ theme }) => ({
  borderRadius: '100%',
  height: '6.25rem',
  border: '.25rem solid white',
  boxShadow: '0px 2px 10px 0px rgba(57, 88, 116, 0.50)',
  [theme.breakpoints.down('lg')]: {
    height: '5.25rem',
  },
}));

const TagCard = styled('div')(({ theme }) => ({
  borderRadius: '.625rem',
  color: theme.palette.blueberry.main,
  backgroundColor: theme.palette.tag.main,
  fontFamily: 'Avenir',
  fontSize: '.625rem',
  fontWeight: 'bolder',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
  padding: '.5rem',
  textAlign: 'center',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.3rem',
  },
  margin: '.5rem'
}));

const AnimatedCardGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: '0 5rem',
  // padding: '0',
  gap: '1rem',
  width: '20%',
  boxSizing: 'border-box',
  animation: `30s ${slideAnimation} infinite linear`,
  [theme.breakpoints.down('sm')]: {
    width: '60%',
  },
}));
const slideAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-120%));
  }
`;

export {
  AnimatedCardGrid,
  AnimatedCard,
  Card,
  CardGrid,
  CardGridServicios,
  CardGridMedicos,
  CardGridUnidades,
  CardGridNoticias,
  DescriptionCard,
  HeaderCardGrid,
  ImageCard,
  NameCard,
  ProfilePicCard,
  TagCard,
  TitleCard
};