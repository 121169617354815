import React, { useState, useEffect } from 'react';
import { getContacto } from '../../api/getContacto';
import { styled } from '@mui/material';
import { ContainerYCenterCenter } from '../../common/Containers';
import {
  FieldContainer,
  InputContainer,
  Input,
  SingleInputContainer,
  OptionContainer,
  SubmitInput,
  TextArea,
  SelectInput,
} from '../../common/Form';
import { HeaderInput } from '../../common/Header';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

const ContactForm = () => {
  const [content, setContent] = useState([]);
  const [image, setImage] = useState('');
  const [recaptcha, setRecaptcha] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    category: '',
    name: '',
    email: '',
    message: '',
  });

  const fetchData = async () => {
    try {
      const response = await getContacto();
      setContent(response.data.attributes);
      setImage(response.data.attributes.image.data[0].attributes);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (!recaptcha || !formData.name || !formData.email || !formData.message) {
      console.log('Error en Google Recaptcha: ', recaptcha);
      alert('Llene todos los campos');
    } else {
      console.log('Success: ', recaptcha);
      fetch('https://utils.strategix4.com/emails/api/hsda/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((result) => {
          console.log(result);
          alert('Formulario enviado exitosamente!');
          window.location.reload();
        })
        .catch((error) => {
          console.log('error', error);
          alert('Error en enviar el formulario');
        });
    }
  };

  const ImageSection = styled('img')(({ theme }) => ({
    width: '31.25rem',
    height: '21.38rem',
    [theme.breakpoints.down('lg')]: {
      width: '19.375rem',
      height: '14.03613rem',
    },
  }));

  return (
    <BodyForm id='contactForm' name='contactForm'>
      <InputContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* CATEGORIA */}
        <SingleInputContainer
          style={{ display: content.nombreCompleto ? 'flex' : 'none' }}
        >
          <HeaderInput>Categoria</HeaderInput>
          <FieldContainer>
            <SelectInput
              id='category'
              name='category'
              value={formData.category}
              onChange={handleInputChange}
              required
            >
              <OptionContainer value='Agendar cita'>
                Agendar cita
              </OptionContainer>
              <OptionContainer value='Quiero pedir información'>
                Quiero pedir información
              </OptionContainer>
              <OptionContainer value='Quiero trabajar en San Diego de Alcalá'>
                Quiero trabajar en San Diego de Alcalá
              </OptionContainer>
              <OptionContainer value='Reportar un problema'>
                Reportar un problema
              </OptionContainer>
            </SelectInput>
          </FieldContainer>
        </SingleInputContainer>

        {/* NOMBRE */}
        <SingleInputContainer
          style={{ display: content.nombreCompleto ? 'flex' : 'none' }}
        >
          <HeaderInput>Nombre Completo</HeaderInput>
          <FieldContainer>
            <Input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              placeholder='Tu nombre completo'
              autoComplete='name'
              required
            />
          </FieldContainer>
        </SingleInputContainer>

        {/* EMAIL */}
        <SingleInputContainer
          style={{ display: content.nombreCompleto ? 'flex' : 'none' }}
        >
          <HeaderInput>Email</HeaderInput>
          <FieldContainer>
            <Input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
              placeholder='Email'
              autoComplete='email'
              required
            />
          </FieldContainer>
        </SingleInputContainer>

        {/* MESSAGE */}
        <SingleInputContainer
          style={{ display: content.nombreCompleto ? 'flex' : 'none' }}
        >
          <HeaderInput>Mensaje</HeaderInput>
          <FieldContainer>
            <TextArea
              id='message'
              name='message'
              value={formData.message}
              onChange={handleInputChange}
              placeholder='Escriba su mensaje aqui'
              required
            ></TextArea>
          </FieldContainer>
        </SingleInputContainer>

        <SubmitInput
          type='button'
          value='Enviar Formulario'
          onClick={handleSubmit}
        />
      </InputContainer>
      {!loading && (
        <ContainerYCenterCenter>
          <ImageSection
            alt=''
            src={process.env.REACT_APP_CMS_URL + image.url}
          />
        </ContainerYCenterCenter>
      )}

      <GoogleReCaptchaProvider reCaptchaKey='6Ld9iM0oAAAAAMt4gxe4mRtS4YHfkJ484Z0CKWLo'>
        <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
      </GoogleReCaptchaProvider>
    </BodyForm>
  );
};

export default ContactForm;

const BodyForm = styled('form')(({ theme }) => ({
  display: 'flex',
  width: '90%',
  height: '100%',
  padding: '2rem',
  marginTop: '2rem',
  borderRadius: '1.25rem',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
