import React, { useEffect } from 'react';
import { styled } from '@mui/material';

function VideoContainer({ src }) {
  const VideoContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      width: '100%',
    },
  }));

  const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    height: '100%',
    gap: '2rem',
    padding: '2rem 0rem',
  }));

  useEffect(() => {
    const vid = document.getElementById('myVideo');
    vid.volume = 0;
  }, []);

  return (
    <Body>
      <VideoContainer>
        <video
          id="myVideo"
          title="YouTube Video Player"
          src={src}
          controls
          autoPlay={false}
          style={{ width: '100%', maxWidth: '100%' }}
        ></video>
      </VideoContainer>
    </Body>
  );
}

export default VideoContainer;
