import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { getNoticias } from '../../api/getNoticias';

//Common
import { Title, HeaderSpaceBetween } from '../../common/Header';
import { CardGridNoticias } from '../../common/Card/index.js';
import { Content } from '../../common/Containers';
import { Card, TitleCard, DescriptionCard } from '../../common/Card/index.js';
import PrimaryButton from '../../common/PrimaryButton';
import theme from '../../styles/index.js';

const NoticiasSection = () => {
  const [content, setContent] = useState([]);
  const fetchData = async () => {
    try {
      const response = await getNoticias();
      setContent(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ImageSection = styled('img')(({ theme }) => ({
    height: '12rem',
    [theme.breakpoints.down('lg')]: {
      height: '7rem',
    },
  }));

  return (
    <Content>
      <HeaderSpaceBetween>
        <Title>Noticias y Eventos</Title>
        <PrimaryButton
          text='Ver Todo'
          url='/noticias'
          color={theme.palette.light.main}
          backgroundColor={theme.palette.blueberry.main}
        />
      </HeaderSpaceBetween>

      <CardGridNoticias>
        {content.slice(0, 3).map((item) => {
          return (
            <Card key={item.id}>
              <ImageSection
                alt=''
                src={
                  process.env.REACT_APP_CMS_URL +
                  item.attributes.image.data.attributes.url
                }
              />
              <TitleCard>{item.attributes.title}</TitleCard>
              <DescriptionCard>
                {item.attributes.shortDescription}
                <ATag
                  href={`/noticias/${encodeURIComponent(
                    item.attributes.title
                  )}`}
                >
                  {' '}
                  leer más...
                </ATag>
              </DescriptionCard>
            </Card>
          );
        })}
      </CardGridNoticias>
    </Content>
  );
};

export default NoticiasSection;

const ATag = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 'bolder',
  color: theme.palette.blueberry.main,
}));
