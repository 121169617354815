import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { getFaq } from '../../api/getFaq';
import { getContactoExtensiones } from '../../api/getContactoExtensiones';
import { getInformacionContacto } from '../../api/getInformacionContacto';

// Componentes
import { ContainerDefault } from '../../common/Containers';
import {
  Description,
  HeaderFlexStart,
  Title,
  Subtitle,
} from '../../common/Header';
import { Content } from '../../common/Containers';
import CustomAccordion from '../../components/Accordion/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ubicacioncontacto from '../../images/ubicacioncontacto.png';
import ContactForm from '../../components/ContactForm';

const Contacto = () => {
  const [content, setContent] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getInformacionContacto();
      setContent(response.data.attributes);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.title = 'Contacto - Hospital San Diego de Alcalá';
  }, []);

  const ImageSection = styled('div')(({ theme }) => ({
    borderRadius: '1.25rem',
    width: '28.125rem',
    height: '23rem',
    backgroundImage: `url(${ubicacioncontacto})`,
    margin: '2rem 0',
    [theme.breakpoints.down('lg')]: {
      width: '16rem',
      height: '18.9375rem',
    },
  }));

  return (
    <ContainerDefault>
      <Content>
        <HeaderFlexStart style={{ padding: '0 1rem' }}>
          <Title>¡Contáctanos!</Title>
        </HeaderFlexStart>
        <ContactForm />
        <HeaderFlexStart style={{ padding: '2rem 1rem' }}>
          <Title>Nuestra información de contacto</Title>
        </HeaderFlexStart>
        <Body>
          <InformacionContactoContainer>
            <ContactoTextbox>
              <FontAwesomeIcon
                style={{ marginRight: '1rem', fontSize: '1.5rem' }}
                icon={faEnvelope}
              />
              <a
                href={`mailto:${content.emailContacto}`}
                style={{
                  width: '100%',
                  wordWrap: 'break-word',
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                {content.emailContacto}
              </a>
            </ContactoTextbox>
            <ContactoTextbox>
              <FontAwesomeIcon
                style={{ marginRight: '1rem', fontSize: '1.5rem' }}
                icon={faPhone}
              />
              <a
                href={`tel:${content.numeroTelefono}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {content.numeroTelefono}
              </a>
            </ContactoTextbox>
            <Subtitle style={{ color: '#395874', margin: '2rem 0' }}>
              Extensiones
            </Subtitle>
            <CustomAccordion apiFunction={getContactoExtensiones} />
          </InformacionContactoContainer>
          <InformacionContactoContainer
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ImageSection />
            <Description>
              Visítanos en Benito Juárez esq.
              <br />
              Fray Hermenegildo. Hermosillo, Sonora.
            </Description>
          </InformacionContactoContainer>
        </Body>
        <HeaderFlexStart style={{ padding: '2rem 1rem' }}>
          <Title>Preguntas frecuentes</Title>
        </HeaderFlexStart>
        <BodyAccordion>
          <CustomAccordion apiFunction={getFaq} />
        </BodyAccordion>
      </Content>
    </ContainerDefault>
  );
};

export default Contacto;

const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '90%',
  height: '100%',
  padding: '2rem',
  marginTop: '2rem',
  borderRadius: '1.25rem',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const InformacionContactoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  color: theme.palette.blueberry.main,
  fontSize: '1.4rem',
  fontWeight: '400',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    width: '90%',
  },
}));

const ContactoTextbox = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  fontFamily: 'Avenir',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'left',
}));

const BodyAccordion = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '90%',
  height: '100%',
  padding: '2rem',
  borderRadius: '1.25rem',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
}));
