import React, { useState, useEffect, useCallback } from 'react';

//Common
import { Content } from '../../common/Containers';
import { Title, HeaderSpaceBetween } from '../../common/Header';
import PrimaryButton from '../../common/PrimaryButton';
import {
  AnimatedCard,
  TitleCard,
  DescriptionCard,
  AnimatedCardGrid,
  ImageCard,
} from '../../common/Card/index.js';
import FormattedContent from '../../common/FormattedContent/index.js';

const AnimatedCardSection = ({
  apiFunction,
  title,
  button,
  url,
  displayButton,
  buttonColor,
  buttonBackgroundColor,
}) => {
  const [content, setContent] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const response = await apiFunction();
      setContent(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [apiFunction]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Content>
      <HeaderSpaceBetween>
        <Title>{title}</Title>
        <div style={{ display: displayButton ? 'none' : 'block' }}>
          <PrimaryButton
            text={button}
            url={url}
            backgroundColor={buttonBackgroundColor}
            color={buttonColor}
          />
        </div>
      </HeaderSpaceBetween>
      <div>
        <AnimatedCardGrid className='card-grid'>
          {content.map((item) => (
            <AnimatedCard key={item.id} className='card'>
              <ImageCard
                alt=''
                src={
                  process.env.REACT_APP_CMS_URL +
                  item.attributes.image.data.attributes.url
                }
              />
              <TitleCard>{item.attributes.title}</TitleCard>
              <DescriptionCard style={{ textAlign: 'center' }}>
                <FormattedContent content={item.attributes.description} />
              </DescriptionCard>
            </AnimatedCard>
          ))}
          {content.map((item) => (
            <AnimatedCard key={item.id} className='card'>
              <ImageCard
                alt=''
                src={
                  process.env.REACT_APP_CMS_URL +
                  item.attributes.image.data.attributes.url
                }
              />
              <TitleCard>{item.attributes.title}</TitleCard>
              <DescriptionCard style={{ textAlign: 'center' }}>
                <FormattedContent content={item.attributes.description} />
              </DescriptionCard>
            </AnimatedCard>
          ))}
          {content.map((item) => (
            <AnimatedCard key={item.id} className='card'>
              <ImageCard
                alt=''
                src={
                  process.env.REACT_APP_CMS_URL +
                  item.attributes.image.data.attributes.url
                }
              />
              <TitleCard>{item.attributes.title}</TitleCard>
              <DescriptionCard style={{ textAlign: 'center' }}>
                <FormattedContent content={item.attributes.description} />
              </DescriptionCard>
            </AnimatedCard>
          ))}
        </AnimatedCardGrid>
      </div>
    </Content>
  );
};

export default AnimatedCardSection;
