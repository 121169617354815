import React, { useState } from 'react';


// import theme from '../../styles/index.js';
import Modal from 'react-modal';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Description } from '../../common/Header/index.js';
import imagenDoctores from '../../images/modalEspecialidades.png';
import SecondaryButton from '../../common/SecondaryButton/index.js';
import { styled } from '@mui/material';
import { ContainerYCenterCenter } from '../../common/Containers/index.js';
import { Card, TitleCard, DescriptionCard, ImageCard,} from '../../common/Card/index.js';
function ModalEspecialidadesCard(props) {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const modalStyles = {
    overlay: { backgroundColor: 'rgba(0,0,0,0.7)', zIndex: 1000 },
    content: {
      width: window.innerWidth > 900 ? '80%' : '100%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      borderRadius: 30,
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
    },
  };

  return (
    <Card
      style={{
        minWidth: '100%',
      }}
      onClick={toggleModal}
    >
      <ImageContainer>
        <ImageCard
          alt=''
          src={
            process.env.REACT_APP_CMS_URL +
            props.item.attributes.image.data.attributes.url
          }
        />
        <TitleCard>{props.item.attributes.title}</TitleCard>
        <DescriptionCard style={{ textAlign: 'center' }}>
          {props.item.attributes.description}
        </DescriptionCard>
      </ImageContainer>
      <Modal
                isOpen={showModal}
                onRequestClose={() => toggleModal()}
                style={modalStyles}
                ariaHideApp={false}
                contentLabel='Create Event Modal'
            >
                <ContainerYCenterCenter>
                    <BodyModal>
                        <span className='close' onClick={toggleModal}>
                            <FontAwesomeIcon
                                style={{ color: 'white', fontSize: '2rem' }}
                                icon={faXmark}
                            />
                        </span>
                        <InnerModalContainer>
                            <TitleModal>
                                {props.item.attributes.title}
                            </TitleModal>
                            <Description>
                                Visita nuestro catálogo de médicos expertos en {props.item.attributes.title}.
                            </Description>
                            <ButtonContainer>
                                <SecondaryButton
                                    props={{
                                        color: '#F4F8FA',
                                        backgroundColor: '#395874',
                                        text: 'Buscar médicos especialistas',
                                        url: '/medicos',
                                        widthMobile: '100%',
                                        justifyContent: 'center',
                                    }}
                                />
                                <SecondaryButton
                                    props={{
                                        color: '#F4F8FA',
                                        backgroundColor: '#5B8EBB',
                                        text: 'Agendar cita',
                                        url: '/contacto',
                                        widthMobile: '100%',
                                        justifyContent: 'center',

                                    }}
                                />
                            </ButtonContainer>
                        </InnerModalContainer>
                        <InnerModalContainer>
                            <ImageModal
                                alt=""
                                src={imagenDoctores}
                            />
                        </InnerModalContainer>
                    </BodyModal>
                </ContainerYCenterCenter>
            </Modal>
    </Card>
  );
}

export default ModalEspecialidadesCard;

const BodyModal = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '90%',
  padding: '2rem',
  marginTop: '2rem',
  borderRadius: '1.25rem',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.15)',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    // height: '80%',
    width: '60%',
    alignItems: 'center',
    gap: '2rem',
    justifyContent: 'center',
  },
}));

const InnerModalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '50%',
  minHeight: '100%',
  color: theme.palette.blueberry.main,
  fontSize: '1.4rem',
  fontWeight: '400',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    width: 'auto',
    gap: '1rem',
  },
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const ImageModal = styled('img')(({ theme }) => ({
  width: '29.5rem',
  height: '18.7rem',
  [theme.breakpoints.down('lg')]: {
    width: '16.5rem',
    height: '10.7rem',
  },
}));

const TitleModal = styled('div')(({ theme }) => ({
  color: '#5B5B5B',
  textAlign: 'center',
  fontFamily: 'Avenir-Black',
  fontSize: '2rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.5rem',
  },
}));

const ImageContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    width: '100%',
  },
}));
